import React,{Fragment,useEffect} from 'react'
import Withoutloginheader from '../../HomeLayout/Header/withoutLoginHeader';

function AboutUs() {

    useEffect(()=>{
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    },[]);
    return (
        <Fragment>
             {localStorage.getItem('loginid') ?"":
             <Withoutloginheader />
                }

                  
            
            <div className='container'>
                <div className='row'>
                    <div className='col-12 text-white text-center mt-3' style={{fontWeight:'700', fontSize: '25px', margin: '15px 0px'}}>
                    About Us
                    </div>
                    <div className='col-12 text-white mt-3'>
                    <b>Passion Paly,</b> is a brand name operating under the umbrella of entertainment company named <b>Passion Paly.</b>
                     {/* which is a joint venture of Bollywood’s media company named <b>Seven Colours Entertainment</b> and <b>Cyber Education & Research Institute of India</b> masterminded by Bollywood’s Writer, Producer, Director <b>Mr. Dweep Raj Kochhar</b> & <b>Mr. Naveen Dham</b> who is a famous personality in the domain of Cyber Security Education.    */}
                    </div>
                    <div className='col-12 text-white mt-3'>
                    At <b>Passion Paly,</b> we want to give facilitate the children with a healthy Entertainment Environment with the flavor of Morality and Education. We want children to refrain from watching vulgar or abusing contents which may ruin their thought process and divert them towards a wrong direction. 
                    </div>
                    <div className='col-12 text-white mt-3'>
                    We will provide them access to Best Motivating and full of Entertainment <b>Movies, Series, Short Films and Music Videos.</b> 
                    </div>
                    <div className='col-12 text-white mt-3'>
                    Not only we will provide Entertainment but we also will promote the talent of children through our <b>Talent Hunt</b> section. In addition to entertainment we have planned to provide them help in their studies through our Academic Videos. Any student sitting anywhere in the country can watch videos of his/her interest in our <b>Student’s Corner</b> section and get help in his/her studies. 
                    </div>
                    <div className='col-12 text-white mt-3'>
                    We are determined to add maximum Principals, Teachers, Motivational Speakers and Intellectuals from all over the world just to motivate and entertain our child spectators. 
                    </div>
                    <div className='col-12 text-white mt-3'>
                    Our prime focus is to provide Clean, Abuse-Free, Non-Vulgar and Patriotic kind of content to our viewers because a healthy mind produces better citizens. 
                    </div>
                   
                   
                </div>
            </div>
        </Fragment>
    )
}

export default AboutUs
