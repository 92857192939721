import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import './SubcategoryVideoCard.scss';

function SubcategoryVideoCard(props) {
    const {VideoList}=props
    console.log(props.VideoList)
    return (
        <Fragment>
            <div class="main_subcategory_video_card">
                <ul class="cards">
                    {VideoList.map((item, index) => {
                        return (
                            <li class="cards_item">
                                <Link
                                
                                    to={{
                                        pathname: '/adminVideoPlayer',
                                        state:{
                                            item:item
                                        }
                                    }} style={{ textDecoration: 'none',width:'100%' }}
                                >
                                    <div class="carddiv">
                                        <div class="card_image"><img src={`https://cinemaadm.testersgroup.com${item.image_path}`} /></div>
 
                                        <div class="card_content">
                                            <h2 class="card_title">{item.title}</h2>
                                            <p class="card_text">Singer: {item.year}</p>
                                            {/* <button class="btn card_btn">Play Video</button> */}
                                        </div>
                                    </div>
                                </Link>
                            </li>

                        )
                    })}
                </ul>
            </div>
        </Fragment>
    )
}

export default SubcategoryVideoCard
