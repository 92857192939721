import React, { useRef, useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
// import {GetmovieListByUserId} from '../../../../store/action/TalentHuntVideoList';
import TalentHuntMultiListCrousel from './TalentHuntMultiListCrousel';
import { GetmovieListByUserId, GetmovieContentType } from '../../../store/action/TalentHuntVideoList';
import ItemsCarousel from 'react-items-carousel';
// import movie1 from 'assets/upcoming/03.jpg';
import { Modal, Button } from 'antd';
import PlyrComponent from 'plyr-react';
import talenthuntvideo from '../../assets/video/talenthuntvideo.mp4';
import { PlayCircleOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom';
import SubcategoryIntroCrousel from './subcategoryIntroCrousel';

function TalentHuntSubCategoryCrousel(props) {
    const ref = useRef()
    const videoList = props.subcategoryList;
    console.log(videoList)
    const [modal2Visible, setmodal2Visible] = useState(false)
    const [subcatvideo,stSubcatvideo]=useState();
    console.log(props.subcategoryList)

    const videomodelCancel = () => {
        console.log(ref.current.plyr)
        ref.current.plyr.pause();
        setModal2Visible(false)
    }
    const setModal2Visible = (modal2Visible,path) => {
        stSubcatvideo(path)
        setmodal2Visible(modal2Visible)
    }
    return (
        <Fragment>
            <SubcategoryIntroCrousel subcategoryList={props.IntroSubcategoryList} />
            {props.subcategoryList.length > 0 ? props.subcategoryList.map((item, index) => {
                return (
                    <>
                        {item.movielist.length > 0 ?
                            <>
                                <div className='container-fluid   mb-3 crouselheader d-flex' style={{ marginTop: '60px', justifyContent: 'space-between' }}>
                                    <h4  className='m-0 crouselheaderheader text-uppercase' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '20px',cursor:'pointer',display:'flex',alignItems:'center' }}>Our {item.name}</h4>
                                    <Link to={`/talenthuntvideo/${item.name}/${item.id}`} className='btn btn-primary'>View All</Link>
                                </div>
                                <TalentHuntMultiListCrousel
                                    moviList={item.movielist}
                                />
                            </>
                            : "Loading..."
                        }
                    </>
                )
            })
                : "Loading...."
            }
            <Modal
                centered
                // title="Vertically centered modal dialog"
                visible={modal2Visible}
                onOk={() => setModal2Visible(false)}
                onCancel={videomodelCancel}
                footer={[
                    <button className='btn btn-danger mr-2' onClick={videomodelCancel}>
                      Cancel
                    </button>,

                    <Link
                      key="link"
                      to="/uploadPage"
                      className='btn btn-primary'
                    //   loading={loading}
                    //   onClick={this.handleOk}
                    >
                      Upload Video
                    </Link>,
                  ]}
        

            >
                <PlyrComponent ref={ref}
                    source={{
                        type: "video",
                        sources: [
                            {
                                src: `https://cinemaadm.testersgroup.com${subcatvideo}`
                            }
                        ],
                        quality: [
                            { default: 576, options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240, "default"] }
                        ]
                    }}
                />
            </Modal>
        </Fragment>
    )
}
// const mapStateToProps = state => ({
//     GetMoviListofuserbysubcategory: state.UploadVideoListByCategoryReducer.GetMoviListofuserbysubcategory,
//     getmovielistbysubvategoryloder: state.UploadVideoListByCategoryReducer.getmovielistbysubvategoryloder,

// })

// const mapDispatchToProps = dispatch => ({

// })
// export default connect(mapStateToProps, mapDispatchToProps)(TalentHuntSubCategoryCrousel);
export default TalentHuntSubCategoryCrousel;
