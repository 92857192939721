// import api from "../api/index";
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import AuthServices from '../authServices';

function loadScript(src) {

    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}
const PaymentGetWayServices = {
    
    PayService: async (userdetail,paymentdetail, price,buypackdata,setBuypackLoader) => {
        
        console.log(price,buypackdata)
        let token = localStorage.getItem("user_token");
        // const headers = {
        //     'Content-Type': 'application/json',
        //     "authorization": `Bearer ${token}`
        // };
        // const location = localStorage.getItem('location')
        // const code = location === 'India' ? "1" : "0"
        //  let token = localStorage.getItem("user_token");
        //  const headers = { Authorization: `${token}` };
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"

        );
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        // creating a new order
        const data = {
            amount: price,
            code: "1",
            user_id:localStorage.getItem("id")
        }
        const result = await axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/order`, data)
        if (result) {
            setBuypackLoader(false)
            console.log(result)
        }
        if (!result) {
            setBuypackLoader(true)
            alert("Server error. Are you online?");
            return;
        }
        const { amount, id: order_id, currency } = result.data.order;
        console.log(amount,order_id,currency)
        const options = {
            key: "rzp_test_gqVuKr7AV8P8GP", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "Passion",
            description: "Test Transaction",
            // image: { logo },
            order_id: order_id,
            handler: async function (response) {
                const razdata = {
                    orderCreationId: order_id,
                    //  razorpayPaymentId: '5',
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };
                console.log(razdata)
                return axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/payments/${razdata.razorpayPaymentId}`)
                    .then((response) => {
                        const successObj = response.data.body
                        console.log(response)
                        const paymentData = {
                            pay_amount: successObj.amount,
                            amount_refunded: successObj.amount_refunded,
                            contact: successObj.contact,
                            currency: successObj.currency,
                            description: successObj.description,
                            email: successObj.email,
                            international: successObj.international,
                            method: successObj.method,
                            payment_order_id: successObj.order_id,
                            payment_getway_id: successObj.id
                        }
                        setBuypackLoader(false)
                        buypackdata['paymentData']=paymentData
                        paymentdetail(buypackdata)
                    })
                    .catch((error) => {
                        setBuypackLoader(true)
                        console.log(error)
                        if (error.response) {
                            return { response: error.response, state: true };
                        } else if (error.request) {
                            console.log(error.request);
                            return { response: null, state: true };
                        } else {
                            console.log("Error", error.message);
                            return { response: null, state: true };
                        }
                        
                    });
            },
            prefill: {
                name: `${userdetail[0].name}`,
                email: `${userdetail[0].email}`,
                contact: `${userdetail[0].mobileno}`,
            },
            notes: {
                address:"Passion Play",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }
};


export default PaymentGetWayServices;

