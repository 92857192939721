
import React, { Fragment, useEffect, useState } from 'react';
import fluidPlayer from 'fluid-player';
import "fluid-player/src/css/fluidplayer.css";
import videofile from '../assets/file.xml'
import add from '../assets/add.xml'
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import { useParams } from 'react-router-dom';
import AdvertiseServices from '../../Services/AdvertisementService/Adservices';
import './adminVideoPlayer.scss'
import axios from 'axios';




const Mobileappplayer = (props) => {
    const params = useParams()
    console.log(window.location.pathname)
    const [playerObj, setPlayer] = useState();
    const [loder, setLoder] = useState(false);
    const [self, setSelf] = useState(React.createRef())
    const [CategoryVideoList, setCategoryVideoList] = useState([]);
    const [categoryType, setCategoryType] = useState('');
    const [at_began, setAtbegan] = useState({ type: "", path: "" });
    const [at_pause, setAtpause] = useState({ type: "", path: "" });
    const [at_time, setattime] = useState({ type: "", path: "" });
    const [addstatus, setaddstatus] = useState(false);
    const [url, seturl] = useState('')
    const [videopath,setvideopath]=useState('')
    const [xmlfiles, setxmlfile] = useState()
    const callapi=async(apidata)=>{
        const fetchdata = await AdvertiseServices.getVideoadlist(apidata)
        console.log(fetchdata)
        if (fetchdata.state === false) {
            //  console.log(fetchdata)
            if (fetchdata.response.data.success === true) {
                // console.log(fetchdata)
                const relt = fetchdata.response.data.result
                // if (relt.at_begin.type !== undefined) {
                // console.log(fetchdata)
                // if (relt.at_begin.type !== "") {
                setAtbegan({ id: relt.at_begin.type ? relt.at_begin.id : '', type: relt.at_begin.type, path: relt.at_begin.file_path })
                setAtpause({ id: relt.at_pause.type ? relt.at_pause.id : '', type: relt.at_pause.type ? relt.at_pause.type : "", path: relt.at_pause.file_path ? relt.at_pause.file_path : "" })
                setattime({ id: relt.at_particlar.type ? relt.at_particlar.id : '', type: relt.at_particlar.type ? relt.at_particlar.type : "", path: relt.at_particlar.file_path ? relt.at_particlar.file_path : "" })
                setaddstatus(true)
                console.log(relt)
                console.log(relt.at_begin.file_path)
                fluidPlayer(
                    'my-video',

                    {
                        layoutControls: {
                            doubleclickFullscreen: false, // Default true
                            subtitlesEnabled: true, // Default false
                            // posterImage: `${item.image_url}`, // Default false
                            title: '', // Default false
                            playbackRateEnabled: true, // Default false
                            controlForwardBackward: {
                                show: true // Default: false
                            }
                        },
                        vastOptions: {
                            showPlayButton: true, // Default false
                            // allowVPAID: true, // Default false.
                            // adText: 'Advertising helps us keep the lights on', // Default null,
                            adTextPosition: 'top left', // Default 'top left'
                            adCTAText: true,
                            adList: [
                                {
                                    roll: 'preRoll',
                                    vastTag: `${videofile}`,
                                    // vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${relt.at_begin.id}`,
                                    // vastTag:'https://rtr.innovid.com/r1.5554946ab01d97.36996823;cb=1982',
                                    // adText: `Advertising supports us directly`

                                },
                                {
                                    roll: 'midRoll',
                                    vastTag: `${videofile}`,
                                    timer: 10
                                },

                                {
                                    roll: 'midRoll',
                                    vastTag: `${add}`,
                                    timer: 15
                                },
                                {
                                    "roll": "onPauseRoll",
                                    vastTag: `${add}`,
                                    // "vastTag": "https://www.videosprofitnetwork.com/watch.xml?key=0185fa78fd7a7789bf1d0ac524fec3f4",
                                    "adText": "",
                                    timer: 10,

                                },
                            ],
                            skipButtonCaption: 'Skip ad in [seconds]',
                            skipButtonClickCaption: 'Skip ad <span class="skip_button_icon"></span>',
                            // adText: 'Advertising helps us keep the lights on',
                            adTextPosition: 'top left',
                            adCTAText: 'Visit now!',
                            adCTATextPosition: 'bottom right',
                            vastTimeout: 1000, // Default 5000
                            maxAllowedVastTagRedirects: 1,

                            vastAdvanced: {
                                vastLoadedCallback: (function () {
                                    console.log('play video')
                                }),
                                noVastVideoCallback: (function () { }),
                                vastVideoSkippedCallback: (function () { }),
                                vastVideoEndedCallback: (function () { })
                            }
                        }
                    }
                )
                // }
            }
        }
    }
    const trigger = async () => {
        const apidata = {
            video_id: params.videoid,
            category_id: params.categoryid,
            sub_category_id: params.subcategory_id
            // video_id: "135",
            // category_id: "1",
            // sub_category_id: "3"
        }
        const videodata = {
            "video_id": params.videoid,
            "category_id": params.categoryid,
            "subcategory_id": params.subcategory_id
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findvideodetail`, videodata)
            .then(res => {
                console.log(res.data.result[0].video_path)
                if (res.data.result.length > 0) {
                    seturl(res.data.result[0].video_path)
                    setvideopath(res.data.result[0].video)
                    callapi(apidata)
                }
            })
            .catch(err => {
                console.log(err)
            })

    }
    useEffect(() => {
        trigger()
        // fluidPlayer(
        //     'my-video',

        //     {
        //         layoutControls: {
        //             doubleclickFullscreen: false, // Default true
        //             subtitlesEnabled: true, // Default false
        //             // posterImage: `${item.image_url}`, // Default false
        //             title: '', // Default false
        //             playbackRateEnabled: true, // Default false
        //             controlForwardBackward: {
        //                 show: true // Default: false
        //             }
        //         },
        //         vastOptions: {
        //             showPlayButton: true, // Default false
        //             // allowVPAID: true, // Default false.
        //             // adText: 'Advertising helps us keep the lights on', // Default null,
        //             adTextPosition: 'top left', // Default 'top left'
        //             adCTAText: true,
        //             adList: [
        //                 {
        //                     roll: 'preRoll',
        //                     // vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${'27'}`,
        //                     "vastTag": `${videofile}`,
        //                     // vastTag:'https://rtr.innovid.com/r1.5554946ab01d97.36996823;cb=1982',
        //                     // adText: `Advertising supports us directly`

        //                 },

        //                 {
        //                     roll: 'midRoll',
        //                     "vastTag": `${add}`,
        //                     timer: 5
        //                 },
        //                 {
        //                     roll: 'midRoll',
        //                     "vastTag": `${videofile}`,
        //                     timer: 10
        //                 },
        //                 {
        //                     "roll": "onPauseRoll",
        //                     "vastTag": `${add}`,
        //                     "adText": "",

        //                 },
        //             ],
        //             skipButtonCaption: 'Skip ad in [seconds]',
        //             skipButtonClickCaption: 'Skip ad <span class="skip_button_icon"></span>',
        //             // adText: 'Advertising helps us keep the lights on',
        //             adTextPosition: 'top left',
        //             adCTAText: 'Visit now!',
        //             adCTATextPosition: 'bottom right',
        //             vastTimeout: 1000, // Default 5000
        //             maxAllowedVastTagRedirects: 1,

        //             vastAdvanced: {
        //                 vastLoadedCallback: (function () {
        //                     console.log('play video')
        //                 }),
        //                 noVastVideoCallback: (function () { }),
        //                 vastVideoSkippedCallback: (function () { }),
        //                 vastVideoEndedCallback: (function () { })
        //             }
        //         }
        //     }
        // )
    }, [])
    return (
        <Fragment>
            {params.categoryid==='1' ?
             <video id='my-video' controls>
             <source src={`https://apicinema.testersgroup.com/api/videostream/${videopath}`}
                 data-fluid-hd
                 title='1080'
                 type='video/mp4' />
         </video>
            
        :
            <video id='my-video' controls>
                <source src={`https://cinemaadm.testersgroup.com${url}`}
                    data-fluid-hd
                    title='1080'
                    type='video/mp4' />
            </video>
}
        </Fragment >
    )
}
export default Mobileappplayer;
