import React, { Fragment, useState, useEffect,useRef } from 'react';
import { Form, Input, Select, Tooltip, Button, Space, Typography } from 'antd';
import SignUpValidation from "../../Validation/SignUpValidation";
import OtpInput from 'react-otp-input';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import AuthServices from '../../../Services/authServices';
import './signup.scss';
import axios from 'axios';
const { Option } = Select;
const { Title } = Typography;



function Signup(props) {
    const [form] = Form.useForm();
    const inputRef=useRef()
    // let user_captcha = document.getElementById('user_captcha_input').value;
    const { schoolsignupshow, studentsignupshow, normalusersignupshow,
        promotersignupshow
    } = props
    const [size, setSize] = useState('default');
    const [promoerror, setPromoerror] = useState('')
    const [promocode, setPromocode] = useState('');
    const [promoerrorcolor, setpromoerrorcolor] = useState('')
    const [promocodedefault,setpromocodedefault]=useState('vhiiiiiiiiiiiiio')
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        otpUi,
        verifyotpbutton,
        otpstate,
        verifyotphandlechange,
        otperror,
        otpverfiyui,
        otphandleChange, usertype, usertypeid, setPromocodeuserid, setPromoSuccess,loader,setLoader

    } = SignUpValidation(props.SignUpUserHandler);
    // const usepromocodedefaultbutton=(value)=>{
    //     console.log(value)
    //     setpromocodedefault('raju kumar')
    // }
    useEffect(()=>{
        onFill();
    },[])
    const onFill = () => {
        form.setFieldsValue({
            promocode:'PC-Passion'
        });
        const typeid="2";
       const  code="PC-Passion"
        findpromocode(typeid,code)
      };
    const findpromocode = async (typeid, code) => {
        const apicall = await AuthServices.findpromocode(typeid, code)
        if (apicall.state === false) {
            console.log(apicall.response.data.result.id)
            setPromocodeuserid(apicall.response.data.result.id)
            // apicall.response.data.msg
            setPromoerror(`This Promoter User code is valid and belongs to ${apicall.response.data.result.name}.`)
            setPromoSuccess(true)
            setpromoerrorcolor('success')
        }
        else {
            setPromoerror(apicall.response.data.error)
            setpromoerrorcolor('danger')

        }
    }
    useEffect(() => {
        loadCaptchaEnginge(6);
    }, [])
    const onFinish = values => {
        console.log('Received values of form: ', values);
    };
    const countrycodehandleChange = e => {
        setSize(e.target.value);
    };
    const promocodeonchange = (e) => {
        console.log(usertype, usertypeid)
        if (e.target.value === '') {
            setPromoerror('')
        }
        var typeid;
        if (usertypeid === "4" || usertypeid === "2" || usertypeid === "1" || usertypeid === "3") {
            if (usertypeid === "2" || usertypeid === "1") {
                typeid = "2"
            }
            else if (usertypeid === "3") {
                typeid = "4"
            }
            else if (usertypeid === "4") {
                typeid = "2"
            }
            console.log(e.target.value)
            findpromocode(typeid, e.target.value)

        }
        else {

        }
    }
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <select
            >
                <option value="+91">+91</option>
            </select>
        </Form.Item>
    );
    return (
        <Fragment>
            {otpverfiyui === false ?
                <Form form={form}
                    initialValues={{
                        email: `${props.UserEmail}`,
                        prefix: '+91',
                        // promocode:promocodedefault
                    }}
                    className='signup_form_div'  layout={`vertical`} name="complex-form" onFinish={onSubmit} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Form.Item >
                        <div className="form-floating mb-3">
                            <Title className='text-white text-center' level={3}>Create Account</Title>
                            <Title className='text-white text-center m-0' level={3}>{usertype}</Title>

                        </div>
                    </Form.Item>
                    <Form.Item label={schoolsignupshow === true ? "Name Of The School" : "Name"}>
                        {/* <Space> */}
                        <Form.Item
                            name="username"
                            noStyle
                            rules={[{ required: true, message: 'Username is required' }]}
                        >
                            <Input placeholder={schoolsignupshow === true ? "Name Of The School" : "Enter Name"} />
                        </Form.Item>
                        {/* </Space> */}
                    </Form.Item>
                    <Form.Item label="Mobile Number">
                        <Space>
                            <Form.Item
                                name={['contact', 'mobileno']}
                                noStyle
                                rules={[{ required: true, min: 10, max: 10, message: 'Mobile number required 10 digits.' }]}
                            >
                                <Input addonBefore={prefixSelector}
                                    style={{ width: '100%' }} placeholder="Enter mobile number" />
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label={schoolsignupshow === true ? "School Email-ID" : "Email"}>
                        <Space>
                            <Form.Item
                                name="email"
                                noStyle
                                setFieldsValue={props.UserEmail}
                                rules={[{ required: true, message: 'Email is required' }]}
                            >
                                <Input placeholder="Enter your email address" defaultValue={props.UserEmail.toString()} />
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    {schoolsignupshow === true ?
                        <>
                            <Form.Item label="Designation School Person">
                                <Space>
                                    <Form.Item
                                        name="desigination_school_person"
                                        noStyle
                                        rules={[{ required: true, message: 'Field is required' }]}
                                    >
                                        <Input placeholder="Enter your Designation" />
                                    </Form.Item>

                                </Space>
                            </Form.Item>
                            <Form.Item label="City">
                                <Space>
                                    <Form.Item
                                        name="city"
                                        noStyle
                                        rules={[{ required: true, message: 'City is required' }]}
                                    >
                                        <Input placeholder="Enter your City" />
                                    </Form.Item>

                                </Space>
                            </Form.Item>
                            <Form.Item label="State">
                                <Space>
                                    <Form.Item
                                        name="state"
                                        noStyle
                                        rules={[{ required: true, message: 'State is required' }]}
                                    >
                                        <Input placeholder="Enter your State" />
                                    </Form.Item>

                                </Space>
                            </Form.Item>
                            <Form.Item label="Pincode">
                                <Space>
                                    <Form.Item
                                        name="pincode"
                                        noStyle
                                        rules={[{ required: true, message: 'Pincode is required' }]}
                                    >
                                        <Input placeholder="Enter your Pincode" />
                                    </Form.Item>

                                </Space>
                            </Form.Item>
                        </>
                        : ""}
                    <Form.Item label="Password">
                        <Space>
                            <Form.Item
                                name="passowrd"
                                noStyle
                                rules={[{ required: true, message: 'Password is required' }]}
                            >
                                <Input.Password placeholder="Enter your password" />

                            </Form.Item>

                        </Space>
                    </Form.Item>
                    <Form.Item label="Confirm Password">
                        <Space>
                            <Form.Item
                                name="cnfpassword"
                                noStyle
                                rules={[{ required: true, message: 'Password is required' }]}
                            >
                                <Input.Password placeholder="Confirm your password" />

                            </Form.Item>

                        </Space>
                    </Form.Item>
                    {/* {promotersignupshow===true || schoolsignupshow === true || normalusersignupshow === true ?
                     <Form.Item>
                     <p className='text-white m-0'>
                        IN CASE YOU DON'T HAVE ANY PROMOTER'S CODE, PLEASE USE <span className='text-success'>PC-Passion</span> AS PROMOTER'S CODE, or <span onClick={onFill}  className='text-primary' style={{cursor:"pointer"}}>CLICK TO FILL</span>
                    </p>
                    </Form.Item>
                    :""} */}
                    
                    {schoolsignupshow === true || studentsignupshow === true || normalusersignupshow === true || promotersignupshow === true ?
                        <Form.Item label={studentsignupshow === true ? "School code" : "Promocode"} style={{display:'none'}}>
                            <Space>
                                <Form.Item
                                    name="promocode"
                                    noStyle                                   
                                    rules={[{ required: true, message: `${studentsignupshow === true ? "School code is required" : "PromoCode is required"}` }]}
                                >
                                    <Input ref={inputRef}   placeholder={`${studentsignupshow === true ? "Enter School Code" : "Enter Your PromoCode"}`} onChange={promocodeonchange} />

                                </Form.Item>

                            </Space>
                        </Form.Item>

                        : ""}

                    <span className={`text-${promoerrorcolor} ant-row ant-form-item d-none`}>{promoerror}</span>

                    <Form.Item>
                        <div className="col mt-3 p-0 d-flex">
                            <LoadCanvasTemplate reloadText='<i class="fa fa-refresh text-white font-weight-bold" aria-hidden="true"></i>' />
                        </div>

                        <div className="col mt-3 p-0">
                            <div><Input placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text"></Input></div>
                        </div>
                    </Form.Item>
                    <Form.Item label=" " className='register_button_div' colon={false} style={{ textAlign: 'center' }}>
                    <Button type="primary"  htmlType="submit" disabled = {`${loader?"disabled":""}`}>
                    {
                        loader ? (
                            <>
                            <span type="primary"  htmlType="submit" className="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>{" "}Loading...
                        </>
                        ):(<>Register</>)
                    }
                    </Button>
                        
                        
                        <Button className='backsign-btn d-none' style={{background:"#fff", color:"#000"}} type="primary" onClick={props.backuserlist}>
                            Back
                        </Button>
                    </Form.Item>
                    {/* <Form.Item label=" " colon={false} style={{ textAlign: 'center' }}>
                        <Button type="primary"  onClick={props.backuserlist}>
                            Back
                        </Button>
                    </Form.Item> */}
                </Form>
                :
                <form className='otp_verify_form'>
                    <h5>OTP Sent your Email Id and Mobile NO.</h5>
                    <OtpInput containerStyle='otp_container'
                        value={otpstate.otp}
                        onChange={otphandleChange}
                        numInputs={4}
                        separator={<span>-</span>}
                    />
                    <button className='btn btn-primary'
                    disabled = {`${loader?"disabled":""}`}
                    onClick={verifyotpbutton}>
                      
                    {
                        loader ? (
                            <>
                            <span type="primary"  htmlType="submit" className="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>{" "}Loading...
                        </>
                        ):(<>Verify OTP</>)
                    }
                       
                    </button>

                </form>
            }
        </Fragment>
    )
}

export default Signup
