import React, { Fragment, useState } from 'react';
import { Tabs, Button, Divider, Checkbox, Menu, Dropdown, Drawer, Modal, Popover } from 'antd';
import { Link } from 'react-router-dom';
import watchlist from '../../assets/HeaderLogo/watchList.png';
import musicimage from '../../assets/HeaderLogo/music.png';
import uploadvideopng from '../../assets/menu/Videodownload.png';
import { SearchOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import logo from '../../assets/HeaderLogo/logo.png'
import Textlogo from '../../assets/HeaderLogo/logo.png';
import MobileViewMenuBar from './MobileViewMenuBar';
import audiomusic from '../../assets/HeaderLogo/audiomusic.png';
import videomusic from '../../assets/HeaderLogo/videomusic.png';
import AutoSearchInput from '../../resuable/AutoSearchInput';
import Login from "../../../components/LoginSignupPage/LoginPage/Login.js";
import Signup from '../../LoginSignupPage/SignupPage/signup';

import './WithoutLoginHeader.scss';



const { TabPane } = Tabs;
function Withoutloginheader() {
  const [visible, setVisible] = useState(false);
  const [musicmodelVisible, setmusicmodelVisible] = useState(false)
  const [movieList, setmovieList] = useState();
  const [mobileviewnavbar, setMobileviewnavbar] = useState('none');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const [serchInputVisible, setserchInputVisible] = useState(false);
  const [showSignIn, setshowSignIn] = useState(false);
  const [showSignup, setshowSignup] = useState(false);
  const [UserEmail, setUserEmail] = useState('');

  const inputsearchhide = () => {
    setserchInputVisible(false)
  };

  const inputsearchhandleVisibleChange = visible => {
    setserchInputVisible(true)
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setmusicmodelVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setmusicmodelVisible(false);
  };


  const toggleButton = () => {
    if (mobileviewnavbar === 'none') {
      setMobileviewnavbar('block')
    }
    else {
      setMobileviewnavbar('none')

    }
  }
  const logoutbutton = () => {
    // setMobileviewnavbar('none')
    localStorage.removeItem('loginid');
    setVisible(false)
    window.location = '/'

  }
  const menu = (
    <Menu className="profile_show">
      <Menu.Item icon={<i className="ri-file-user-line"></i>}>
        <Link to='/loginprofile'>
          Manage Profile
        </Link>
      </Menu.Item>
      <Menu.Item icon={<i className="ri-file-user-line"></i>}>
        <Link to='/dashboardContent'>
          Dashboard
        </Link>
      </Menu.Item>
      <Menu.Item icon={<i className="ri-file-user-line"></i>}>
        <Link to='/uploadPage'>
          Upload Your Video
        </Link>
      </Menu.Item>
      <Menu.Item icon={<i className="ri-file-user-line"></i>}>
        <Link to='/uplodmovieList'>
          Manage Your Video
        </Link>
      </Menu.Item>
      <Menu.Item icon={<i className="ri-logout-circle-line"></i>}>
        <Link onClick={logoutbutton}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setmusicmodelVisible(true);
    setVisible(false);
  };
  const onClose = () => {
    setVisible(false);

  };



  const OperationsSlot = {
    left:
      <Tabs defaultActiveKey="1" >
        <TabPane tab={<Link to='/HomePage' className="textlogobutton" >
          <img src={Textlogo} width='170px' height='100%' />
        </Link>} key="1">
        </TabPane>
      </Tabs>
  };


  const options = ['left', 'right'];
  const signupbuttonClick = (modal2Visible) => {
    setshowSignup(modal2Visible)
  };
  const signinbuttonclick = (modal2Visible) => {
    setshowSignIn(modal2Visible)
  };
  return (
    <Fragment>
      <div className='container-fluid  homeScreen_header_container'>
        <div className='row header_nav_row'>
          <div className='col-12 header_nav_col'>
            <div className='image_header'>
              <Link to='/'>
                <img  src={Textlogo} width='170px' height='100%' />
              </Link>
            </div>
            <div className='signup_signin_button'>
              <Button type="primary" className='signup_button' onClick={() => signupbuttonClick(true)}>
                Sign Up
              </Button>
              <Button type="primary" className='signin_button' onClick={() => signinbuttonclick(true)}>
                Sign In
              </Button>
            </div>

          </div>
        </div>
        <Modal
          destroyOnClose={true}
          centered
          visible={showSignIn}
          onOk={() => signinbuttonclick(false)}
          onCancel={() => signinbuttonclick(false)}
        >
          <Login UserEmail={UserEmail} />
        </Modal>
        <Modal className='signupmodel'
          destroyOnClose={true}
          centered
          visible={showSignup}
          onOk={() => signupbuttonClick(false)}
          onCancel={() => signupbuttonClick(false)}
        >
          <Signup UserEmail={UserEmail} />
        </Modal>
      </div>


    </Fragment>
  )
}

export default Withoutloginheader;
