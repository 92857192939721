
import React,{useEffect} from 'react';
import axios from "axios";
// import plyr from 'react-plyr';

export default function Videoplay() {

    useEffect(() => {

    }, [])
    return (
        <div className='container'>
             <div className='row '>
                    <div className='col-12'>
                        <video width="100%" height="400" controls>
                            {/* <source src={video1} type="video/mp4" width='100%' /> */}
                            <source src={`https://apicinema.testersgroup.com/api/videostream`} type="video/mp4" width='100%' />
                            <track label="English" kind="subtitles" srclang="en" src='' default />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div> 
        </div>
    )
}
