import { Fragment } from 'react';
import ForgetPasswordValidation from '../../Validation/ForgetPasswordValidation';
import { Form, Input, Button, Checkbox, Space, Typography } from 'antd';
import OtpInput from 'react-otp-input';
const { Title } = Typography

const FOrgetPassword = (props) => {
    const { state, handlechange, otpSendButton, verifyOtpUi, otpstate, verifyotphandlechange, verifyotpbutton,
        forgetpasswordUi, inputotpui, createpassword, createPasswordHandlechange, createpasswordButton, cancelVerifyotpUiButton,
        createPasswordsucess,Resetpwd,loader,setLoader } = ForgetPasswordValidation(props.setForgetPasswordUi,props.setForgetPasswordUI);
        const onSubmit=()=>{
            console.log('submit')
        }
        const gologinButton=()=>{
            props.setForgetPasswordUI(false)
        }
    return (
        <Fragment>
            <div style={{ display: `${forgetpasswordUi}` }}>
                {verifyOtpUi === false ?
                    <Form
                        // initialValues={{
                        //     email: `${props.UserEmail}`,
                        // }}
                        className='signup_form_div' layout={`vertical`} name="complex-form" onFinish={otpSendButton} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>

                        <Form.Item >
                            <div className="form-floating mb-3">
                                <Title className='text-white' level={3}>Reset Your Password</Title>
                            </div>
                        </Form.Item>
                        <Form.Item label="Email or Mobile Number">
                            <Space>
                                <Form.Item
                                    name="email"
                                    noStyle
                                    setFieldsValue={state.email}
                                    rules={[{ required: true, message: 'Email is required' }]}
                                >
                                    <Input placeholder="Email address or mobile number"  />
                                </Form.Item>
                            </Space>
                        </Form.Item>

                        <Form.Item label=" " colon={false} style={{ textAlign: 'center' }}>
                            
                             <Button type="primary"  htmlType="submit" disabled = {`${loader?"disabled":""}`}>
                                                          {
                                                              loader ? (
                                                                  <>
                                                                  <span type="primary"  htmlType="submit" className="spinner-border spinner-border-sm" role="status"
                                                                  aria-hidden="true"></span>{" "}Loading...
                                                              </>
                                                              ):(<>Reset</>)
                                                          }
                                                          </Button>
                            <br></br><br></br>
                            <a onClick={gologinButton} className='text-white' type="primary" htmlType="submit">
                                Back to Login
                            </a>
                            {/* <div className='forget_password'>
                                <a className="login-form-forgot"  onClick={cancelVerifyotpUiButton}>
                                    Cancel Button
                                </a>
                            </div> */}

                        </Form.Item>
                    </Form>
                    // <form>
                    //     <div className="form-floating mb-3 ">
                    //         <div className="mb-3 p-1 text-left alert alert-success" style={{ fontWeight: '500', fontSize: '12px' }}>Enter Your Valid Email Address</div>
                    //         <input type="email " class="form-control logininputdiv" id="floatingInput" onChange={handlechange} name="email" value={state.email} placeholder="Enter Your Valid Email" />
                    //     </div>
                    //     <div class="form-floating  mt-1 text-center">
                    //         <button className='btn bg-none p-0 text-primary' onClick={otpSendButton}>Send OTP Your Email</button>
                    //         <button className='btn bg-none p-0 text-primary ml-3' onClick={cancelVerifyotpUiButton}>Cancel</button>
                    //     </div>
                    // </form>
                     :
                     <form className='otp_verify_form'>
                     <h5>OTP has been sent to your registered mobile number and email</h5>
                     <OtpInput containerStyle='otp_container'
                         value={otpstate.otp}
                         onChange={verifyotphandlechange}
                         numInputs={4}
                         separator={<span>-</span>}
                     />

                    <button className='ant-btn ant-btn-primary mt-3'
                    disabled = {`${loader?"disabled":""}`}
                    onClick={verifyotpbutton}>
                      
                    {
                        loader ? (
                            <>
                            <span type="primary"  htmlType="submit" className="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>{" "}Loading...
                        </>
                        ):(<>Verify OTP</>)
                    }
                       
                    </button>
                    
 
                 </form>
                    // <form>
                    //     <div className="form-floating mb-3 ">
                    //         <div className="mb-3 p-1 text-left alert alert-success" style={{ fontWeight: '500', fontSize: '12px' }}>OTP Send your register Email Address</div>
                    //         <input type="text " class="form-control logininputdiv" id="floatingInput" onChange={verifyotphandlechange} name="otp" value={otpstate.otp} placeholder="Enter OTP" />
                    //     </div>
                    //     <div class="form-floating  mt-1 text-center">
                    //         <button className='btn bg-none p-0 text-primary' onClick={verifyotpbutton}>Verify OTP</button>
                    //     </div>
                    // </form>
                    }
            </div>

            <div className='main_reset_pass signup_form_div' style={{ display: `${inputotpui}` }}>
                {createPasswordsucess === false ?

                    <div className='innr_reset'>
                        <div className="form-floating mb-3">
                            <Title className='text-white' level={3}>Forget Password</Title>
                         </div>
                        <form>
                            <div className="form-floating mb-3 ">
                            
                            <label for="floatingInput mb-2">Create Password</label>
                            <input type="text " class="ant-input form-control logininputdiv" id="floatingInput" onChange={createPasswordHandlechange} name="password" value={createpassword.password} placeholder="Enter Password" />
                            </div>
                            <div className="form-floating mt-2 ">
                            <input type="text " class="ant-input form-control logininputdiv" id="floatingInput" onChange={createPasswordHandlechange} name="cnfpassword" value={createpassword.cnfpassword} placeholder="Enter Confirm Password" />
                            </div>
                            {Resetpwd===true ?
                            <div className='text-danger'>Passowrd Not Matched!</div>
                            :""}
                            {/* <small className="form-text text-danger  mb-2 " style={{ marginLeft: '67px' }}>{errors.email && "This Field Is required"}</small> */}

                        </form>
                        <div class="form-floating  mt-1 text-center">
                            

                            <button className='ant-btn ant-btn-primary mt-3'
                    disabled = {`${loader?"disabled":""}`}
                    onClick={createpasswordButton}>
                      
                    {
                        loader ? (
                            <>
                            <span type="primary"  htmlType="submit" className="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>{" "}Loading...
                        </>
                        ):(<>Create Password</>)
                    }
                       
                    </button>
                        </div>
                    </div>
                    :
                    <div className='alert alert-success'>
                       Password has been reset successfully
                    </div>
                }


            </div>
        </Fragment>
    )
}
export default FOrgetPassword;