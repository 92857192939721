import React, { Fragment, useEffect, useState } from 'react';
import UploadVideoValidationForm from '../../Validation/UploadVideoValidationForm';
import Select from 'react-select';
import { ProgressBar } from 'react-bootstrap';
import DashBord from '../../../components/HomeLayout/UserDashboard/index';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TermConditionPage from '../../resuable/TermConditionPage';
import { Line, Circle } from 'rc-progress';
import {Link} from 'react-router-dom';

import './UploadVideoPage.css';
const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#1a1a1a', color: 'white' }),
}

export default function UploadVideoPage(props) {
    const [shownav, setShownav] = useState(false);
    const [showForm, setShowForm] = useState('block');
    const [imagePreview, setImagePreview] = useState('')

    const { uploadVideoButton, register, handleSubmit, errors,
        contenttypeHandlechange, contentType, successuploadmsg, show, findcategory, categoryList, statusupload, handleChange,
        subcatList, contentTypelist, uploadsuccessLoder, loadloder, uploadPercentage, imageHandler, uploadmovieImage,
        uploadmovieVideo, videoHandler } = UploadVideoValidationForm(props.updatedata)
    const options = [
        { value: 'sadMusic', label: 'Sad Music' },
        { value: 'math', label: 'Math' },
        { value: 'englishsong', label: 'English Song' }
    ]

    const handleClose = () => {

        setShownav(false);
        setShowForm('block')
    }
    const handleShow = () => setShownav(true);
    //    console.log(props.updatedata)

    useEffect(() => {
        findcategory()
    }, [])
    return (
        <Fragment>
            <div className='container-fluid' style={{ minHeight: '40vh' }}>
                <Modal show={shownav} size='lg' animation={false} style={{ zIndex: '9999' }}>
                    <Modal.Header>
                        <Modal.Title>Terms & Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                        <TermConditionPage />
                    </Modal.Body>
                    <Modal.Footer>
                    <Link to='/HomePage' className='ant-btn text-white' style={{ background: 'red' }}>No Thanks</Link>
                        <Button className='ant-btn text-white' variant="" style={{ background: '#007bff' }} onClick={handleClose}>
                            Accept Terms & Conditions
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className='container-fluid' style={{ display: `${showForm}`, padding: '0px' }}>
                    <div className='row'>
                        <div className='col-md-3 col-lg-2 d-sm-none d-md-block d-lg-block p-0'>
                            <DashBord />
                        </div>
                        <div className='col-md-9 col-lg-10'>
                            <>
                                {loadloder === true ?


                                    <Modal show={show}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        animation={false}>
                                        <Modal.Body style={{ background: '#000000' }}>
                                            <div>
                                                <span className='text-white'>Please Wait ,While We are Uploading Your Video Content</span>
                                            </div>
                                            {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={`${uploadPercentage}%`} />}
                                        </Modal.Body>
                                    </Modal>

                                    :
                                    <div>
                                        {statusupload === false ? "loading" :
                                            <div>
                                                {successuploadmsg !== '' ?
                                                    <div className='container '>
                                                        {uploadsuccessLoder === true ? "video loading..." :
                                                            <div className='row m-5'>
                                                                <div className='col-12' style={{ textAlign: 'center' }}>
                                                                    <img src="https://img.icons8.com/emoji/48/000000/check-box-with-check-emoji.png" />
                                                                    <h5 style={{ color: 'greenyellow' }}>{successuploadmsg}</h5>
                                                                    <p className='text-white'>
                                                                        Note:  Your video has been uploaded successfully, it will be shown in the selected category only after admin's approval. We will notify you by an email
                                                                </p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div className='container-fluid  uploaddivcontainerfluid'>
                                                        <div className='row p-3 py-2' style={{ background: "#141414", borderBottom: '1px solid rgb(0 0 0)' }}>
                                                            <div className='col-12'>
                                                                <h4 className='text-white text-left'>Add Your Video Content</h4>
                                                            </div>
                                                        </div>
                                                        <div className='row  uploaddivrow pt-5' style={{ background: "#141414", borderBottom: '1px solid rgb(0 0 0)' }}>
                                                            <div className='col-12'>
                                                                <form onSubmit={handleSubmit(uploadVideoButton)}>
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control uploadforminput"
                                                                            placeholder="Name of the Artist"
                                                                            {...register("title", {
                                                                                required: "Required",
                                                                            })}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                    <div className='container-fluid'>
                                                                        <div className='row'>
                                                                            <div className='col-md-6 p-0 pt-3'>
                                                                                <div class="form-group " style={{ paddingRight: '5px' }}>
                                                                                    <label class="text-left text-white d-block" for="">Upload Image</label>
                                                                                    <input type="file" class="form-control uploadforminput" id="img" style={{ display: '' }}
                                                                                        placeholder='Choose Image File'
                                                                                        name="filename" accept="image/*"
                                                                                        {...register("filename", {
                                                                                            required: "Required",
                                                                                        })}
                                                                                        onChange={imageHandler}
                                                                                    />

                                                                                </div>
                                                                                <div class="form-group " style={{ marginRight: '5px', height: '245px', background: "" }}>
                                                                                    <div className='container'>
                                                                                        <div className='row '>
                                                                                            <div className='col-12'>
                                                                                                <img src={uploadmovieImage} width='100%' height='250px' />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-6 p-0 pt-3'>
                                                                                <div class="form-group" style={{ paddingLeft: '5px' }}>
                                                                                    <label class="text-left text-white d-block" for="">Upload Video</label>
                                                                                    <input type="file" class="form-control uploadforminput" placeholder="Upload Video" title="Choose a video please" id="videofile" style={{ display: '' }}
                                                                                       accept="video/*"
                                                                                       {...register("videofile", {
                                                                                            required: "Required",
                                                                                        })}
                                                                                        onChange={videoHandler}
                                                                                    />

                                                                                </div>
                                                                                <div class="form-group" style={{ marginLeft: '5px', height: '250px', background: "" }}>
                                                                                    <video width="100%" height="240px" controls>
                                                                                        <source src={uploadmovieVideo} type="video/mp4" />
                                                                                                Your browser does not support the video tag.
                                                                                    </video>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-row mt-3">
                                                                        <div class="form-group col-md-12 col-lg-3 mt-3">
                                                                            <select class="form-control uploadforminput " name='category'
                                                                                style={{ background: '#1a1a1a', color: 'white' }}
                                                                                {...register("category", {
                                                                                    required: "Required",
                                                                                })}
                                                                                onChange={handleChange}

                                                                            >
                                                                                <option value="" >Select Category</option>
                                                                                {categoryList === undefined ? "" : categoryList.map((item, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <option  value={item.id} >{item.name}</option>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </select>                    </div>
                                                                        <div class="form-group col-md-12 col-lg-3 mt-3">
                                                                            <select name="cars" class="form-control uploadforminput" style={{ background: '#1a1a1a', color: 'white' }}
                                                                                {...register("subcategory", {
                                                                                    required: "Required",
                                                                                })}
                                                                                onChange={handleChange}
                                                                            >
                                                                                <option value="">Sub Category</option>
                                                                                {subcatList === null ? "" :
                                                                                    <>
                                                                                        {subcatList.map((item, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <option value={item.id} >{item.name}</option>
                                                                                                </>
                                                                                            )
                                                                                        })}
                                                                                    </>}
                                                                            </select>
                                                                        </div>
                                                                        <div class="form-group col-md-12 col-lg-6 mt-3">
                                                                            <Select isMulti className='m-0 form-control pro-dropdown basic-multi-select p-0' options={contentTypelist}
                                                                                styles={colourStyles}
                                                                                placeholder='Select Content Type ...'

                                                                                onChange={contenttypeHandlechange}
                                                                            />                     </div>

                                                                    </div>
                                                                    <div class="form-group mt-5">
                                                                        <textarea class="form-control uploadforminput desc"  id="exampleFormControlTextarea1" placeholder='Descripition' rows="3"
                                                                            name='desc'
                                                                            {...register("desc", {
                                                                                required: "Required",
                                                                            })}
                                                                            onChange={handleChange}

                                                                        />
                                                                    </div>



                                                                    <div class="form-row">

                                                                        <div class="form-group col-md-12 text-left mt-5 mb-3">
                                                                            <button className='ant-btn' style={{ background: "#3f9fff", color: "#ffffff", marginRight: '10px' }} onClick={handleSubmit(uploadVideoButton)}>Submit</button>
                                                                            <button className='ant-btn ml-2' style={{ background: "#545E75", color: "#ffffff" }}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        </div>

                    </div>
                </div>
            </div>

        </Fragment>
    )
}
