
import React, { useState } from 'react';
import notifyimg1 from '../../components/assets/notify/thumb-1.jpg';
import {Link} from 'react-router-dom';


class AutoSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ["Bulbasaur", "Ivysaur", "Venusaur", "Charmander", "Charmeleon", "Charizard", "Squirtle", "Wartortle", "Blastoise", "Caterpie", "Metapod", "Butterfree", "Weedle", "Kakuna", "Beedrill", "Pidgey", "Pidgeotto", "Pidgeot", "Rattata", "Raticate", "Spearow", "Fearow", "Ekans", "Arbok", "Pikachu", "Raichu", "Sandshrew", "Sandslash", "Nidoran♀", "Nidorina", "Nidoqueen", "Nidoran♂", "Nidorino", "Nidoking", "Clefairy", "Clefable", "Vulpix", "Ninetales", "Jigglypuff", "Wigglytuff", "Zubat", "Golbat", "Oddish", "Gloom", "Vileplume", "Paras", "Parasect", "Venonat", "Venomoth", "Diglett", "Dugtrio", "Meowth", "Persian", "Psyduck", "Golduck", "Mankey", "Primeape", "Growlithe", "Arcanine", "Poliwag", "Poliwhirl", "Poliwrath", "Abra", "Kadabra", "Alakazam", "Machop", "Machoke", "Machamp", "Bellsprout", "Weepinbell", "Victreebel", "Tentacool", "Tentacruel", "Geodude", "Graveler", "Golem", "Ponyta", "Rapidash", "Slowpoke", "Slowbro", "Magnemite", "Magneton", "Farfetch'd", "Doduo", "Dodrio", "Seel", "Dewgong", "Grimer", "Muk", "Shellder", "Cloyster", "Gastly", "Haunter", "Gengar", "Onix", "Drowzee", "Hypno", "Krabby", "Kingler", "Voltorb", "Electrode", "Exeggcute", "Exeggutor", "Cubone", "Marowak", "Hitmonlee", "Hitmonchan", "Lickitung", "Koffing", "Weezing", "Rhyhorn", "Rhydon", "Chansey", "Tangela", "Kangaskhan", "Horsea", "Seadra", "Goldeen", "Seaking", "Staryu", "Starmie", "Mr. Mime", "Scyther", "Jynx", "Electabuzz", "Magmar", "Pinsir", "Tauros", "Magikarp", "Gyarados", "Lapras", "Ditto", "Eevee", "Vaporeon", "Jolteon", "Flareon", "Porygon", "Omanyte", "Omastar", "Kabuto", "Kabutops", "Aerodactyl", "Snorlax", "Articuno", "Zapdos", "Moltres", "Dratini", "Dragonair", "Dragonite", "Mewtwo", "Mew",
            ],
            // data:
            //     this.props.movieList,
              list: undefined,
        }
    }

    searchData(e) {
        var queryData = [];
        if (e.target.value != '') {
            this.state.data.forEach(function (person) {
                if (person.title.toLowerCase().indexOf(e.target.value) != -1) {
                    if (queryData.length < 10) {
                        queryData.push(person);
                    }
                }
            });
        }
        this.setState({ list: queryData });
    }
    
    render() {
        return (
            <div>
                <SearchBar search={this.searchData.bind(this)} />
                {(this.state.list) ? <SearchResult data={this.state.list} /> : null}
            </div>
        )
    }
}

class SearchBar extends React.Component {
    render() {
        return (
            <div>
                <input onChange={this.props.search}  placeholder="type here to search..." style={{background:'#151515', width: '100%', height: '40px',color:'#ffffff',border:'1px solid #ffffff',boxShadow:'none',outline:'none' }} />
            </div>
        )
    }
}

class SearchResult extends React.Component {

    render() {
        return (
          
            <>
            {this.props.data.length===0 ? "":
            <div style={{ height: '250px', overflowY: 'scroll', background: '#151515' }}>
                {this.props.data.map(function (value,index) {
                    return <Item key={value} val={value} />
                })}
            </div>
    }
            </>
        )

    }

}

class Item extends React.Component {

     refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 500);
        console.log('page to reload')
    }
    render() {
        return (
            <>
                <Link
                  to={{
                    pathname: '/video',
                    state: {
                        item:this.props.val,
                       // contenttype: props.getmovieContentType
                    }
                }}
                onClick={this.refreshPage.bind()}
                 className='notificationcardmenu' href="#/action-1">
                    <div style={{ display: 'inline-flex' }}>
                        <img src={`https://cinemaadm.testersgroup.com/api/fetchUplaodImage/${this.props.val.image}`} width='60px' height='60px' className='mr-3' />
                        <div className='media-body ' >
                            <h6 className='m-0 font-weight-bold'>{this.props.val.title}</h6>
                            <small>Just Now</small>
                        </div>
                    </div>
                </Link><br></br>

            </>
        )
    }

}


export default AutoSearchInput;