import React, { Fragment, useEffect, useState } from 'react';
import sachinmovie from '../assets/HomeSlider/sachin-banner.jpeg';
import akkadbakkad from '../assets/video/akkad bakkad movie.mp4';
import sachinMovie from '../assets/video/sample-video.mp4'
import slider1 from '../assets/HomeSlider/IMG-20180206-WA0022.jpg';
import slider2 from '../assets/HomeSlider/slider2.jpg';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loder from './Loder';
import { ReactVideo } from 'reactjs-media';
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import ItemsCarousel from 'react-items-carousel';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import PlyrComponent from 'plyr-react';
import './bannerCrousel.scss';




const BannerCrousel = (props) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 0;
    const [duration, setDuration] = useState(0)

    console.log(props.bannerVideo)
    const [loder, setLoder] = useState(false)
    const url = 'http://apicinema.testersgroup.com/storage/banner-image/'
    var slideIndex = 0;
    const plusDivs = (e, n) => {
        console.log(n)
        showDivs(slideIndex += n);
    }
    const showDivs = (n) => {
        var i,
            x = document.getElementsByClassName("video-slide"),
            y = document.getElementsByTagName("video");


        if (n > x.length) {
            slideIndex = 1
            //   for (i = 0; i < x.length; i++) {
            //     x[i].style.display = "none";  
            //     y[i].pause();
            //   }

            //   x[slideIndex-1].style.display = "block";
        }

        if (n < 1) {
            slideIndex = x.length

        }

        for (i = 0; i < x.length; i++) {
            x[i].style.display = "none";
            y[i].play();
        }

        x[slideIndex - 1].style.display = "block";
        y[slideIndex - 1].style.display = "none";
        setTimeout(() => {
            y[slideIndex - 1].style.display = "block";
        }, 3000)


    }
    //   const firsttimediv=(n)=>{
    //     var i,
    //     x = document.getElementsByClassName("video-slide"),
    //     y = document.getElementsByTagName("video");
    //     for (i = 0; i < x.length; i++) {
    //         x[i].style.display = "none";  
    //         y[i].pause();
    //       }

    //       x[slideIndex-1].style.display = "block";
    //   }
    // const showDivs = (n) => {
    //     var i,
    //         x = document.getElementsByClassName("video-slide"),
    //         y = document.getElementsByTagName("video")

    //     if (n > x.length) {
    //         console.log(x[1])
    //         slideIndex = 0
    //         for (i = 0; i < x.length; i++) {

    //             x[i].style.display = "none";
    //             y[i].pause();
    //         }

    //         x[slideIndex ].style.display = "block";
    //         y[slideIndex ].style.display = "none"


    //         setTimeout(() => {
    //             y[slideIndex ].style.display = "block"
    //             y[slideIndex ].play()
    //         }, 3000)
    //     }
    //     if (n === x.length) {
    //         console.log(x[1])
    //         slideIndex = 0
    //         for (i = 0; i < x.length; i++) {

    //             x[i].style.display = "none";
    //             y[i].pause();
    //         }

    //         x[slideIndex ].style.display = "block";
    //         y[slideIndex].style.display = "none"


    //         setTimeout(() => {
    //             y[slideIndex - 1].style.display = "block"
    //             y[slideIndex - 1].play()
    //         }, 3000)
    //     }

    //     if (n < x.length) {
    //         console.log(x[1])
    //         slideIndex = x.length
    //         for (i = 0; i < x.length; i++) {

    //             x[i].style.display = "none";
    //             y[i].pause();
    //         }

    //         x[slideIndex + 1].style.display = "block";
    //         y[slideIndex + 1].style.display = "none"


    //         setTimeout(() => {
    //             y[slideIndex + 1].style.display = "block"
    //             y[slideIndex + 1].play()
    //         }, 3000)
    //     }



    // }
    // const showdivfirst=(n)=>{
    //     var i,
    //     x = document.getElementsByClassName("video-slide"),
    //     y = document.getElementsByTagName("video")

    //     console.log(x[1])
    //     slideIndex = 1
    //     for (i = 0; i < x.length; i++) {

    //         x[i].style.display = "none";
    //         y[i].pause();
    //     }

    //     x[slideIndex -1].style.display = "block";
    //     y[slideIndex -1].style.display = "none"


    //     setTimeout(() => {
    //         y[slideIndex-1 ].style.display = "block"
    //         y[slideIndex -1].play()
    //     }, 3000)


    // }
    // const leftDivs=(e,n)=>{
    //     var i,
    //     x = document.getElementsByClassName("video-slide"),
    //     y = document.getElementsByTagName("video")
    //     slideIndex = 1
    //     console.log(x.length)
    //         for (i = 0; i < x.length; i++) {

    //             x[i].style.display = "none";
    //             y[i].pause();
    //         }

    //         x[x.length -1].style.display = "block";
    //         y[x.length -1].style.display = "none"


    //         setTimeout(() => {
    //             y[x.length-1 ].style.display = "block"
    //             y[x.length -1].play()
    //         }, 3000)

    // }
    // const rightDivs=(e,n)=>{
    //     var i,
    //     x = document.getElementsByClassName("video-slide"),
    //     y = document.getElementsByTagName("video")
    //     if(n>x.length){
    //         slideIndex = 1
    //         console.log(x.length)
    //             for (i = 0; i < x.length; i++) {

    //                 x[i].style.display = "none";
    //                 y[i].pause();
    //             }

    //             x[0].style.display = "block";
    //             y[0].style.display = "none"


    //             setTimeout(() => {
    //                 y[0 ].style.display = "block"
    //                 y[0].play()
    //             }, 3000)

    //     }
    //     if(n<x.length){
    //     slideIndex = 1
    //     console.log(x.length)
    //         for (i = 0; i < x.length; i++) {

    //             x[i].style.display = "none";
    //             y[i].pause();
    //         }

    //         x[n +1].style.display = "block";
    //         y[n +1].style.display = "none"


    //         setTimeout(() => {
    //             y[n+1 ].style.display = "block"
    //             y[n +1].play()
    //         }, 3000)
    //     }
    //     if(n===x.length){
    //         slideIndex = 1
    //         console.log(x.length)
    //             for (i = 0; i < x.length; i++) {

    //                 x[i].style.display = "none";
    //                 y[i].pause();
    //             }

    //             x[x.length -1].style.display = "block";
    //             y[x.length -1].style.display = "none"


    //             setTimeout(() => {
    //                 y[x.length-1 ].style.display = "block"
    //                 y[x.length -1].play()
    //             }, 3000)
    //     }

    // }
    // const plusDivs = (e, n) => {
    //     console.log(n)
    //     showDivs(slideIndex += n);
    // }
    const handleDurations = (e, videoValue) => {
        console.log(videoValue)
    }
    const handleDuration = (e, index) => {
        var x = document.getElementsByClassName('video-slide');
        console.log(x.length)

        //  var video = document.getElementsByTagName('video');
        //        console.log(index)
        //        video.pause();
        //video.currentTime = 0;
        //  video.load();
        // handleDurations()
        //    console.log(videoValue)
        // var video = document.getElementsByTagName('video');

        //  video.pause();
        //  video.currentTime = 0;
        //  video.load();

    }
    useEffect(() => {
        if (props.bannerVideo.length > 0) {
            // var x= document.getElementsByClassName('video-container').getAttribute('value')
            // console.log(x)
            setLoder(true)
            setTimeout(() => {
                var x = document.getElementsByClassName('video-slide');
                console.log(x[0])
                // console.log(x[1])
                // console.log(x[2])
                // console.log(x[3])
                // console.log(x[4])
                // console.log(x[5])

            }, 500)



        }
        else {
            setLoder(false)
        }

    }, [])
    const onTimeUpdateProp = (event, currentTime, duration) => {
        // some logic is place here
    }

    return (
        <Fragment>
            {loder === true ?
            <div className='video-container m-0' style={{ padding: `0 ${chevronWidth}px` }}>
                <ItemsCarousel
                    infiniteLoop={true}
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={1}
                    gutter={20}
                    leftChevron={
                        <button class="video-slider-btn left-side " onClick={(e) => handleDuration(e, activeItemIndex)}
                            //onClick={(e) => plusDivs(e, -1)}
                            style={{ position: 'absolute', top: '38%', left: '10px' }}>
                            <svg style={{

                                position: 'absolute', top: '95px', right: '-5px', zIndex: '10000', strokeDashoffset: '126', strokeDasharray: '126 126 0',
                                transform: 'rotate(0deg)', borderRadius: '50%'
                            }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="44px" height="44px" id="circle" fill="none" stroke="currentColor">
                                <circle r="20" cy="22" cx="22" id="test">
                                </circle>
                            </svg>
                            <i class="fa fa-angle-left pl-1" style={{ color: "#ffffff", fontSize: '18px', background: 'red', width: '35px', height: '35px', borderRadius: '50%', lineHeight: '35px' }}></i>
                        </button>
                    }
                    rightChevron={
                        <button class="video-slider-btn right-side"
                            onClick={(e) => handleDuration(e, activeItemIndex)}
                            //onClick={(e) => plusDivs(e, 1)}
                            style={{ position: 'absolute', top: '38%', right: '20px' }}>
                            <svg style={{
                                position: 'absolute', top: '95px', right: '-5px', strokeDashoffset: '126', strokeDasharray: '126 126 0',
                                transform: 'rotate(0deg)', borderRadius: '50%'
                            }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="44px" height="44px" id="circle" fill="none" stroke="currentColor">
                                <circle r="20" cy="22" cx="22" id="test">
                                </circle>
                            </svg>
                            <i class="fa fa-angle-right pl-1" style={{ color: "#ffffff", fontSize: '18px', background: 'red', width: '35px', height: '35px', borderRadius: '50%', lineHeight: '35px' }}></i>
                        </button>
                    }
                    outsideChevron
                    chevronWidth={chevronWidth}
                >
                    {props.bannerVideo === 'Something Error' || props.bannerVideo.length < 0 ?
                        <div className='container-fluid'>

                            <div className='row'>
                                <div className='col-12 bg-white bannerdatanotfounddiv' style={{ height: '' }}>
                                    Data Not Found

                                </div>
                            </div>
                        </div>
                        : props.bannerVideo.map((item, index) => {
                            return (
                                <div key={index} className='video-container'>
                                    {item.video_path === null ?
                                
                                     <div class="video-slide" style={{ width: '100%', height: '100%', backgroundImage: `url(${item.image_url.replace(/ /g, "%20")})` }}>
                                         {item.video_path === null ? ""

                                             :
                                             <ReactPlayer
                                                 key={`https://cinemaadm.testersgroup.com${item.video_path}`}
                                                 url={`https://cinemaadm.testersgroup.com${item.video_path}`}
                                                 controls={true}
                                                 playing={true}
                                                 muted={true}
                                                 width='100%'

                                                 className='reactplayerdiv'
                                                 style={{ margin: 'auto' }}
                                                 onDuration={(e) => handleDurations(e, index)}
                                             />
                                         }
                                     </div>
                                    :
                                    <Link
                                        to={{
                                            pathname: '/videoDetailPage',
                                            state: {
                                                item: item,
                                            }
                                        }}
                                    >
                                        <div class="video-slide" style={{ width: '100%', height: '100%', backgroundImage: `url(${item.image_url.replace(/ /g, "%20")})` }}>
                                            {item.video_path === null ? ""

                                                :
                                                <ReactPlayer
                                                    key={`https://cinemaadm.testersgroup.com${item.video_path}`}
                                                    url={`https://cinemaadm.testersgroup.com${item.video_path}`}
                                                    controls={true}
                                                    playing={true}
                                                    muted={true}
                                                    width='100%'

                                                    className='reactplayerdiv'
                                                    style={{ margin: 'auto' }}
                                                    onDuration={(e) => handleDurations(e, index)}
                                                />
                                            }
                                        </div>
                                    </Link>
                        }
                                </div>
                            )
                        })}

                </ItemsCarousel>
            </div>
             :
                <div className='container-fluid'>

                    <div className='row'>
                        <div className='col-12 bg-white bannerdatanotfounddiv' style={{ height: '' }}>
                            <img src='https://image.freepik.com/free-photo/abstract-grunge-decorative-relief-navy-blue-stucco-wall-texture-wide-angle-rough-colored-background_1258-28311.jpg'
                                width='100%' height='100%' />

                        </div>
                    </div>
                </div>
            } 


        </Fragment>
    )
}

export default BannerCrousel;