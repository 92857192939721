import React, { Fragment, useEffect, useState } from 'react';
import userimage from '../../assets/user/user.jpg'
import { Link } from 'react-router-dom';
import talenthuntimg from '../../assets/talentHunt/talent.jpg';
import { Drawer, Modal, Button, Radio, Space, Tabs } from 'antd';
import watchlist from '../../assets/HeaderLogo/watchList.png';
import musicimage from '../../assets/HeaderLogo/music.png';
import uploadvideopng from '../../assets/menu/Videodownload.png';
import logo from '../../assets/HeaderLogo/logo.png'
import audiomusic from '../../assets/HeaderLogo/audiomusic.png';
import videomusic from '../../assets/HeaderLogo/videomusic.png';

import './Footer.scss';
const { TabPane } = Tabs;

const Footer = () => {
    const [mobileviewnavbar, setMobileviewnavbar] = useState('none')
    const [Login, setLogin] = useState(localStorage.getItem('loginid'));
    const [mobileFixedFooterVisible, setmobileFixedFooterVisible] = useState(false);
    const [musicmodelVisible, setmusicmodelVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');

    const fixedfootershowDrawer = () => {
        setmobileFixedFooterVisible(true)
    };

    const fixeddroweronClose = () => {
        setmobileFixedFooterVisible(false)

    };

    const toggleButton = () => {
        if (mobileviewnavbar === 'none') {
            setMobileviewnavbar('block')
        }
        else {
            setMobileviewnavbar('none')

        }
    }
    const sideaccountLink = () => {
        setMobileviewnavbar('none')

    }
    const logoutbutton = () => {
        setMobileviewnavbar('none')
        localStorage.removeItem('loginid');
        window.location = '/'

    }
    const loginLinkButton = () => {
        setMobileviewnavbar('none')

    }
    useEffect(() => {

    }, [])
    const showModal = () => {
        setmusicmodelVisible(true);
    };
    const handleOk = () => {
        setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        setTimeout(() => {
            setmusicmodelVisible(false);
            setConfirmLoading(false);
        }, 2000);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setmusicmodelVisible(false);
    };
    const MusicshowModal = () => {
        setmobileFixedFooterVisible(false)
        setmusicmodelVisible(true);
      };



    return (
        <Fragment>
             <div className='container-fluid mt-5 pb-3 px-5 pt-0 shadow-sm webviewfooter' style={{ background: " #222222" }}> {/*#0a1015 */}
                <div className='container-fluid mt-5 p-0 shadow-sm footer_sm'>
                <div className='row' style={{justifyContent: 'center', borderTop: '1px solid #000'}}>
                    <div className='col-12'>  
                        <div className='kidzjoy_app_store d-block gap-4 d-lg-flex align-items-center justify-content-center justify-content-lg-between px-3 px-lg-5'>
                        <h3 className='text-white' style={{fontSize: '22px', textAlign: 'center', marginBottom: '0px',}}>Download Passion Play App</h3>
                        <div className='d-flex gap-3 mt-5 mt-lg-0'>
                        <div className='Google_Play'>
                            <a href='#' target="_blank">
                            <img src="Google_play.png" width='100%' height='55px' alt="Google" />
                            </a>
                        </div>
                        <div className='App_Store'>
                          <img src="App_Store.png" width='100%' height='53px' alt="App Store" />
                        </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className='row pt-2 pb-5 justify-content-center' style={{ marginLeft: '10px', marginRight: '10px', background: "" }}>
                        <div className='col-12 text-white'>
                            <ul className='p-0 list-unstyled foot-ul d-flex flex-wrap gap-4 justify-content-center align-items-center'>
                                <li>
                                <Link to='/Watch-movie' className='text-white'>
                                    Movies
                                </Link>
                                </li>
                                <li>
                                    <Link to='/web-series' className='text-white'>
                                        Series
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/shortfilmpage' className='text-white'>
                                        Short Films
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/talenthuntlist' className='text-white'>
                                        Shorts
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/studentcorner' className='text-white'>
                                        Artists
                                    </Link>
                                </li>
                                {/* <li>Movies</li>
                                <li>Tv Show</li>
                                <li>Corporate information</li> */}
                                 <li>
                                    <Link to='/privacy-policy' className='text-white'>
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/refund-policy' className='text-white'>
                                        Refund Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/terms-and-conditions' className='text-white'>
                                        Terms & Conditions
                                    </Link>

                                </li>
                                <li>
                                    <Link to='/disclaimer' className='text-white'>
                                        Disclaimer
                                    </Link>
                                </li>
                                <li>Help</li>
                            </ul>
                        </div>
                       
                        <div className='col-12 col-sm-4 text-white'>
                            <div class="d-flex justify-content-center">
                                <a href="#" class="s-icon">
                                <i class="fab fa-facebook-f"></i>
                                </a>
                                <a href="#" class="s-icon">
                                <i class="fab fa-instagram"></i>
                                </a>
                                <a href="#" class="s-icon">
                                <i class="fab fa-twitter"></i>
                                </a>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div className='footer_copy_right'>
                    <p className='text-center' style={{color:'#8197a4'}}>@copyright 2025 - <a className='' href='#' style={{color:'#8197a4'}}>Passion Play </a>- All Rights Reserved<a className='text-white' href='#'></a></p>
                </div>

            </div>
            <div id="manageviewport" style={{ background: 'red', display: `${mobileviewnavbar}`, zIndex: '10000' }}>
                <div id="sidebar" style={{ background: 'black' }}>

                    <ul className="nav pl-2 pr-2 mt-3">
                        {!Login ? '' :

                            <li className='pt-2 pb-2 ' style={{ borderBottom: '1px solid grey', width: '100%', color: '#ffffff' }} >
                                <i class="ri-file-user-line text-primary"></i>
                                <Link to='/loginprofile' className='' onClick={sideaccountLink} style={{ border: 'none' }}>Manage Profile</Link>
                            </li>
                        }
                        {!Login ? '' :

                            <li className='pt-2 pb-2 ' style={{ borderBottom: '1px solid grey', width: '100%', color: '#ffffff' }} >
                                <i class="ri-file-user-line text-primary"></i>
                                <Link to='/dashboardContent' className='' onClick={sideaccountLink} style={{ border: 'none' }}>Dashboard</Link>
                            </li>
                        }
                        {!Login ? '' :

                            <li className='pt-2 pb-2 ' style={{ borderBottom: '1px solid grey', width: '100%', color: '#ffffff' }} >
                                <i class="ri-file-user-line text-primary"></i>
                                <Link to='/uplodmovieList' className='' onClick={sideaccountLink} style={{ border: 'none' }}>Upload Video</Link>
                            </li>
                        }
                        {!Login ? '' :

                            <li className='pt-2 pb-2 ' style={{ borderBottom: '1px solid grey', width: '100%', color: '#ffffff' }} >
                                <i class="ri-file-user-line text-primary"></i>
                                <Link to='/uploadPage' className='' onClick={sideaccountLink} style={{ border: 'none' }}>Manage Video</Link>
                            </li>
                        }
                        <li className='pt-2 pb-2' style={{ borderBottom: '1px solid grey', width: '100%', color: '#ffffff' }} >
                            <i class="ri-logout-circle-line text-primary"></i>
                            {!Login ?
                                <Link style={{ border: 'none' }} onClick={loginLinkButton} data-toggle="modal" data-target="#staticBackdrop">Login</Link>
                                :

                                <Link style={{ border: 'none' }} onClick={logoutbutton}>Logout</Link>

                            }
                        </li>
                    </ul>
                </div>
            </div>

            <div className='container-fluid mt-5 mb-2 p-0 shadow-sm mobileviewfooter' style={{ background: "#1a1a1a" }}>
                <div className='container-fluid mt-3 p-0 shadow-sm'  >
                    <div className='row pt-3 pb-5' style={{ marginLeft: '0px', marginRight: '0px', background: "" }}>
                        <div className='col-12 text-white'>
                            <ul className='p-0'>
                                <li className='mb-2 footernavlink'>About Us</li>
                                {/* <li className='mb-2 footernavlink'>Movies</li>
                                <li className='mb-2 footernavlink'>Tv Show</li>
                                <li className='mb-2 footernavlink'>Corporate information</li> */}
                                <li className='mb-2 footernavlink'>Privacy Policy</li>
                                <li className='mb-2 footernavlink'>Terms & Conditions</li>
                                <li className='mb-2 footernavlink'>Help</li>
                                <li className='mb-2 footernavlink'>FAQ</li>
                                <li className='mb-2 footernavlink'>Contact Us</li>
                                <li className='mb-2 footernavlink'>Legal Notice</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            {!Login ? "" :
                <div className='container-fluid  mobile-footer' style={{ background: '#202020', zIndex: '10000' }}>
                    <div className='row'>
                        <div className='col-12'>
                            <ul class="nav fixedfooternav" style={{ fontSize: '25px', justifyContent: 'space-between' }}>
                                <li class="nav-item">
                                    <a class="nav-link active p-0" style={{ textAlign: 'center' }}>
                                        <i class="fas fa-video text-white"></i>                                </a>
                                    <p style={{ fontSize: '15px', color: '#ffff', textAlign: 'center' }}>Video</p>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link p-0" style={{ textAlign: 'center' }}>
                                        <i class="fas fa-music text-white"></i>
                                        <br></br>
                                    </a>
                                    <p style={{ fontSize: '15px', color: '#ffff', textAlign: 'center' }}>Music</p>
                                </li>
                                <li class="nav-item">
                                    <Link
                                        to={{
                                            pathname: '/studentcorner',
                                            state: {
                                                loder: false
                                            }
                                        }}
                                    >
                                        <a class="nav-link p-0" href="#" style={{ textAlign: 'center' }}>
                                            <i class="fa fa-user text-white"></i><br></br>
                                        </a>
                                        <p style={{ fontSize: '15px', color: '#ffff', textAlign: 'center' }}>Student's</p>
                                    </Link>
                                </li>
                                <li class="nav-item p-0">
                                    <Link
                                        to={{
                                            pathname: '/talenthuntlist',
                                            state: {
                                                loder: false
                                            }
                                        }}
                                    >
                                        <a class="nav-link disabled p-0" href="#" style={{ textAlign: 'center' }}>
                                            <img src={talenthuntimg} class="img-fluid avatar-40 rounded-circle mobilefooteravtar" alt="user" />
                                        </a>
                                        <p style={{ fontSize: '15px', color: '#ffff', textAlign: 'center' }}>Shorts</p>
                                    </Link>
                                </li>
                                {!Login ?
                                    <li class="nav-item" data-toggle="modal" data-target="#staticBackdrop">
                                        <a class="nav-link disabled p-0" href="#" style={{ textAlign: 'center' }}>
                                            <i class="fas fa-sign-in-alt text-white"></i>                            </a>
                                        <p style={{ fontSize: '15px', color: '#ffff', textAlign: 'center' }} data-toggle="modal" data-target="#staticBackdrop">Login</p>
                                    </li>
                                    :
                                    <li class="nav-item" onClick={fixedfootershowDrawer}>
                                        <a class="nav-link disabled p-0" href="#" style={{ textAlign: 'center' }}>
                                            <img src={userimage} class="img-fluid avatar-40 rounded-circle mobilefooteravtar" alt="user" />
                                        </a>
                                        <p style={{ fontSize: '15px', color: '#ffff', textAlign: 'center' }} onClick={fixedfootershowDrawer}>Me</p>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>

                </div>

            }
            <Drawer className='fixed_footer_drawer'
                title={<img src={logo} width='100px' height='80px' />} placement={'left'}
                closable={false}
                onClose={fixeddroweronClose}
                visible={mobileFixedFooterVisible}
            >
                <Tabs tabPosition={`left`} className='side_navbr_tabs'>
                    <TabPane
                        tab={<Link to='/loginprofile' onClick={() => { setmobileFixedFooterVisible(false) }}>
                            Manage Profile
                        </Link>}
                        key="1">
                    </TabPane>
                    {/* <TabPane
                        tab={<Link to='/uploadPage'>
                            Upload Your Video
                        </Link>}
                        key="2">
                    </TabPane> */}



                    <TabPane tab={<span>
                        <img src={watchlist} width='30px' height='30px' />&nbsp;&nbsp;&nbsp;
                        watchList
                    </span>
                    } key="4">
                    </TabPane>
                    <TabPane tab={<span onClick={MusicshowModal}>
                        <img src={musicimage} width='30px' height='30px' />&nbsp;&nbsp;&nbsp;
                        Music
                    </span>
                    } key="5">
                    </TabPane>
                    <TabPane tab={<Link className='text-white' to='/uploadPage' onClick={() => { setmobileFixedFooterVisible(false) }} size={`small`}>
                        <img src={uploadvideopng} width='30px' height='30px' />&nbsp;&nbsp;&nbsp;
                        Upload Video
                    </Link>
                    } key="6">
                    </TabPane>
                    <TabPane tab={<Link className='text-white' to='/uplodmovieList' onClick={() => { setmobileFixedFooterVisible(false) }} size={`small`}>
                        {/* <img src={uploadvideopng} width='30px' height='30px' />&nbsp;&nbsp;&nbsp; */}
                        Manage Your Video
                    </Link>
                    } key="6">
                    </TabPane>
                    <TabPane
                        tab={<Link onClick={logoutbutton}>
                            Logout
                        </Link>}
                        key="7">
                    </TabPane>
                </Tabs>
            </Drawer>
            <Modal
                title="Choose Your Category"
                visible={musicmodelVisible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <ul className='' style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <li className='d-block text-center'>
                        <Link to='/music-audio' onClick={handleCancel}>
                            <img className='audiomusicimage' src={audiomusic} alt='' /><br></br>
                            <span>Audio Music</span>
                        </Link>

                    </li>
                    <li className='d-block text-center'>
                        <Link to='/music-video' onClick={handleCancel}>
                            <img className='audiomusicimage' src={videomusic} alt='' /><br></br>
                            <span>Video Music</span>
                        </Link>

                    </li>

                </ul>
            </Modal>
        </Fragment>
    )
}
export default Footer;
