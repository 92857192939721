import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { LOCAL_SERVER, PANCHANG_SERVER } from "../../store/config";
import { Button, notification, Space } from 'antd';
import AuthServices from '../../Services/authServices';

import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useHistory } from "react-router-dom";



export default function LoginFormValidation(
  FindLoginUserHandler,
  loginUserData
) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const navigate = useNavigate();
  const routerHistory = useHistory();

  const [loginerror, setloginerror] = useState("");
  const [loader,setLoader] = useState(false);

  const createNotification = (type) => {
    console.log(type);
    return () => {
      if (type === "success") {
        //   console.log('success')
        NotificationManager.success("Success message", "Title here");
      }
    };
  };
  const onSubmit = async (data, e) => {
    //  e.preventDefault();
    console.log(data);
    setLoader(true)
    const apicall = await AuthServices.logIn(data);
    console.log(apicall)
    if (apicall.state === false) {
      setLoader(false)
      if (apicall.response.data.success === true) {
        if(apicall.response.data.result.block_status==="0" || apicall.response.data.result.block_status===null){
          console.log(apicall)
          if (apicall.response.data.user_status === 'old User') {
            console.log('old user')
  
            if (apicall.response.data.result.sub_exp_status === '0' && apicall.response.data.result.subscription_status === '1') {
              localStorage.setItem("user_type", apicall.response.data.result.type_property_id);
              localStorage.setItem("loginid", apicall.response.data.result.id);
              localStorage.setItem("user_token", apicall.response.data.token);
  
              const userdata = {
                user_id: apicall.response.data.result.id
              }
              // navigate('/HomePage');
              routerHistory.push('/HomePage');
              // window.location = "/HomePage";
              notification['success']({
                message: 'Successfully Logged In'
              });
            }
            else if (apicall.response.data.result.sub_exp_status === '1' && apicall.response.data.result.subscription_status === '0') {
              localStorage.setItem("id", apicall.response.data.result.id);
  
              //  window.location = "/renew/planform";
              routerHistory.push('/renew/planform');
            }
          }
          else if (apicall.response.data.user_status === 'new User') {
            // new user 
            localStorage.setItem("id", apicall.response.data.result.id);
            //window.location = "/planform"
            routerHistory.push('/planform');
            console.log('new user')
          }
          else {
            console.log('something went wrong try again later')
          }
        }
        else{
          console.log('blocked')
          notification['error']({
            message: 'Your account has been blocked, please contact customer care'
          });
        }
      }
      else {
        console.log('Something went wrong')
      }
    }
    else {
      setLoader(false)
      notification['error']({
        message: 'Invalid Credential!'
      });
      console.log('user not exist')
    }



    // old code 
    // if (apicall.state === false) {
    //   if (apicall.response.data.success === true) {
    //     if (apicall.response.data.subscription_status === '1') {

    //       localStorage.setItem("user_type", apicall.response.data.usertype);
    //       localStorage.setItem("loginid", apicall.response.data.result[0].id);
    //       localStorage.setItem("user_token", apicall.response.data.token);
    //       const userdata = {
    //         user_id: apicall.response.data.result[0].id
    //       }
    //       window.location = "/HomePage";
    //       notification['success']({
    //         message: 'Login Success!'
    //       });
    //     }
    //     else {
    //       localStorage.setItem("id", apicall.response.data.result[0].id);
    //       window.location = "/planform";
    //     }
    //   }
    //   else {
    //     notification['error']({
    //       message: 'Password Not match !',
    //       description: 'Enter Your Valid Password'
    //     });
    //   }
    // }
    // else {
    //   notification['error']({
    //     message: 'Password Not match !',
    //     description: 'Enter Your Valid Password'
    //   });
    // }

    // FindLoginUserHandler(data)
    // setTimeout(()=>{
    //     console.log(loginUserData)

    // },1000)
  };
  return { onSubmit, register, handleSubmit, errors, loginerror,loader,setLoader };
}
