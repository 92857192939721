import React, { Fragment, useEffect ,useState} from 'react';
import { Link } from 'react-router-dom';
import watchlIstimage from '../../components/assets/menu/watchlIstimage.png';
import SharePopover from '../../components/Pages/SeriesPage/EpisodeDetailPage/shareButton';
import axios from 'axios';
import Loder from '../../components/resuable/Loder';
import './videoDetailPage.scss';

const VideoDetailPage = (props) => {
    const item = props.location.state.item;
    const [videoDetail,setVideodetail]=useState();
    const [loder,setLoder]=useState(false)
   // console.log(item);
    const items={
        video_path:item.moviebannervideopath
    }
   useEffect(()=>{
       const data={
           category_id:item.category_id,
           video_id:item.video_id
       }
       
       console.log(item)
    axios.post('https://apicinema.testersgroup.com/api/findVideoDetailbycategory&id',data)
    .then(res=>{
        if(res.status===200){
            console.log(res.data)
            setVideodetail(res.data)
            setLoder(true)
        }

    })
    .catch(err=>{
        console.log(err)
    })
   },[])

    return (
        <Fragment>
            {loder===true ?
            <div className='container mt-2'>
                <div className='row'>
                    {/* <div className='col-sm-12 col-md-6 text-white col-md-pull  first'> */}
                    <div className='col-md-6 text-white col-md-push-6 first'>
                        <nav aria-label=" bg-none">
                            <ol class="d-flex p-0">
                                <li class=""><Link to="/">Home</Link>
                                    <i class="fas fa-angle-double-right"></i>
                                </li>
                                {/* <li class=""><Link to="/seriespage">Series</Link>
                                    <i class="fas fa-angle-double-right"></i>
                                </li> */}
                                <li class="" aria-current="page">{videoDetail.detail[0].title}</li>
                            </ol>
                        </nav>
                        <h4 className=''>
                        {videoDetail.detail[0].title}
                        </h4>
                        <div className='mt-3'>
                                Language:<span className='languagespan' style={{ color: '#007bff' }}>&nbsp;
                                    {videoDetail.language.map((item, inde) => {
                                        return " " + item
                                    })}
                                </span>
                            </div>
                            <div className='episodedetaildivdesc'>
                                Genre:<span className='languagespan' style={{ color: '#007bff' }}>&nbsp;
                                    {videoDetail.contenttype.map((item, inde) => {
                                        return " " + item
                                    })}
                                </span>
                            </div>
                        <div className='mt-4 episodedetaildiv  '>
                                <Link className='btn btn-success'
                                 to={{
                                    pathname: '/adminVideoPlayer',
                                    state: {
                                        item: videoDetail.detail[0],
                                    }
                                }} 
                               >Play Now</Link>
                                <span className='ml-3'>
                                    <img src={watchlIstimage} width='20px' height='20px' />
                                    <span className='ml-2'>Add TO WatchList</span>
                                </span>
                                <SharePopover />
                               
                        </div>
                        <div class="read-more mb-12 mt-2" style={{ lineClamp: "3" }}>
                                    <input id="read-more-checkbox" type="checkbox" class="read-more__checkbox" aria-hidden="true" />
                                    <p class="read-more__text mb-2">
                                    description
                                    </p>
                                    <label for="read-more-checkbox" class="read-more__label" style={{ color: '#007bff', boxShadow: 'none' }} data-read-more="Read more" data-read-less="See less" aria-hidden="true"></label>
                        </div>
                    </div>
                    <div className='col-md-6 col-md-pull-6 second'>
                        <div className='' style={{ height: '350px' }}>
                            <img className=''  
                            src={`https://cinemaadm.testersgroup.com${videoDetail.detail[0].image_path}`}
                                width='100%' height='100%'
                            />
                        </div>
                    </div>
                </div>
            </div>
            :
                <Loder />
                            }
        </Fragment>
    )
}

export default VideoDetailPage;