import React, {useState } from 'react';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';
import axios from 'axios';

function VideoMusicListValidation(setUserPlayList) {
    const [AudioMusicList,setAudioMusicList]=useState([])
    // const [UserPlayList,setUserPlayList]=useState([])
    const [UserPlayListitem,setUserPlayListitem]=useState([])


    const fetchAudiMusicList=(id)=>{
        const data={
            subcategory_id:id
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/video_music_list`,data)
        .then(res=>{
            if(res.data.msg==='list find success'){
                if(res.data.result.length>0){
                    console.log(res.data.result)
                    setAudioMusicList(res.data.result)
                }
                else{
                    console.log('list empty')
                }
            }
        })
        .catch(err=>{
            console.log(err)
        })
    }
    const getplaylistbyuserId=(id)=>{
        const data={
            user_id:localStorage.getItem('loginid'),
             song_id:id
        }
        axios.post('https://apicinema.testersgroup.com/api/getplaylist_by_userId',data)
        .then(res=>{
            if(res.data.msg==='list find success'){
                console.log(res)
                setUserPlayList(res.data.playList)
                setUserPlayListitem(res.data.playlistitem)
            }
        })
        .catch(err=>{
            console.log(err)
        })
    }
    const deleteplaylistbyuserId=(id)=>{
        const data={
            user_id:localStorage.getItem('loginid'),
            playlist_id:id
        }
        axios.post('https://apicinema.testersgroup.com/api/deleteplaylist_by_userid',data)
        .then(res=>{
            if(res.data.msg==='list find success'){
                getplaylistbyuserId()
            }
        })
    }
   

    return {fetchAudiMusicList,AudioMusicList,getplaylistbyuserId,deleteplaylistbyuserId,UserPlayListitem}
}

export default VideoMusicListValidation
