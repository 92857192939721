import React, { Fragment, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Icon, Col, Card, Row } from 'antd';
import { Link  } from 'react-router-dom'

import axios from 'axios';
import './VideoCardList.scss'



function VideoCardList(props) {
    const { Meta } = Card;
    const { AudioMusicList } = props;
    console.log(AudioMusicList)

    const [items, setItems] = useState([])
    const [noMore, setnoMore] = useState(true);
    const [page, setPage] = useState(2)

    const getcomments = () => {
        axios.get('https://jsonplaceholder.typicode.com/comments?_page=1&_limit=20')
            .then(res => {
                setItems(res.data)
            })
    }
    useEffect(() => {
        getcomments()
        console.log(AudioMusicList)
    }, [])
    const fetchcomments = () => {
        axios.get(`https://jsonplaceholder.typicode.com/comments?_page=${page}&_limit=20`)
            .then(res => {
                console.log(res)
                setItems([...items, ...res.data])
                if (res.data.length === 0 || res.data.length < 20) {
                    setnoMore(false)
                }
                setPage(page + 1)
            })
    }
    const fetchData = async () => {
        await fetchcomments()
        // setItems([...items,...commentsfromserver])
        // console.log('hello')
    }
    return (
        <Fragment>
           
            <div class="main">
                <ul class="cards">
                    {AudioMusicList.map((item, index) => {
                        return (
                            <li class="cards_item">
                                <Link
                                    to={{
                                        pathname: `/video-play/${item.title.replace(/ /g,"-")}/${item.id}`,
                                        item: item
                                    }} style={{ textDecoration: 'none' }}
                                >
                                    <div class="carddiv">
                                        <div class="card_image"><img src={`https://cinemaadm.testersgroup.com${item.image_path}`} /></div>
                                        <div class="card_content">
                                            <h2 class="card_title">{item.title.substring(0, 15) + "..."}</h2>
                                            <p class="card_text">Singer: {item.singer_name.substring(0, 15) + "..."}</p>
                                            <button class="btn card_btn">Play Music</button>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
            {/* <InfiniteScroll
                dataLength={items.length} //This is important field to render the next data
                next={fetchData}
                hasMore={noMore}
                loader={<h4 className='text-white'>Loading...</h4>}
                endMessage={
                    <p className='text-white' style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
          
                >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                    {AudioMusicList.map((item, index) => {
                        return (
                            <Col className='mt-2 mb-2' lg={6} md={8} sm={12} xs={12}>
                                <Link 
                                  to={{
                                    pathname: `/audio-player`,
                                    item:item.audio_video_path
                                }} style={{ textDecoration: 'none' }}
                                >

                                    <Card
                                        hoverable
                                        cover={<img alt="example" height='150px' src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
                                    >
                                        <Meta title="Europe Street beat" description="www.instagram.com" />
                                    </Card>
                                </Link>


                            </Col>
                        )
                    })}


                </Row>

            </InfiniteScroll> */}

        </Fragment>
    )
}

export default VideoCardList
