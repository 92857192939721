import React, { Fragment } from 'react';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';
import { Link } from 'react-router-dom';
import './MovieTabCrouselCard.scss'

const MovieTabCrouselCard = (props) => {
    return (
        <Fragment>


            <div className='itemimagetag show_watch' style={{ position: 'relative' }}>
                <img className='itemimagetag show_watch' style={{ borderRadius: '3px', boxShadow: "0 2px 5px 0 rgb(34 34 34 / 40%)" }}
                    src={`https://cinemaadm.testersgroup.com${props.item.image_path}`} type='file/png'
                    width='100%'></img>
                <div className='add_watch_icon_home'><p className='txt_addw'><i class="fas fa-heart"></i> Add To Watch List</p></div>
            </div>

        </Fragment>


    )
}
export default MovieTabCrouselCard;
