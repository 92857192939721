export const LOCAL_SERVER = process.env.NODE_ENV === 'production' ? `https://apicinema.testersgroup.com` : `https://apicinema.testersgroup.com`; 
export const PANCHANG_SERVER = 'api';

// for Admin Image Path
export const  ADMIN_PATH='https://cinemaadm.testersgroup.com'

// https://apicinema.testersgroup.com
// https://apicinema.testersgroup.com
// use for video call app
export const MovieVideoType={
    latestRelase:'Recently Added',
    Trending:"Trending",
    Upcoming:"Upcoming"
}

export const WebShowType={
    Upcoming:"Upcoming",
    Trending:"Trending"
}