import React, { Fragment,useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import watchlIstimage from '../../../assets/menu/watchlIstimage.png';
import SharePopover from '../../../Pages/SeriesPage/EpisodeDetailPage/shareButton';
import Loder from '../../../resuable/Loder';
import {LOCAL_SERVER,PANCHANG_SERVER} from '../../../../store/config';
import axios from 'axios';
import '../../../resuable/videoDetailPage.scss';

const TalentHuntVideoDetailPage = (props) => {
    window.scrollTo(0, 0);
    const item = props.location.state.item;
    const [videoDetail,setVideodetail]=useState();
    const [loder,setLoder]=useState(false)
    console.log(item);
    const items={
        video:item.moviebannervideopath
    }
    useEffect(()=>{
        window.scrollTo(0, 0);
        const data={
            category_id:item.category_id,
            video_id:item.video_id
        }
        
        console.log(data)
     axios.post(`https://apicinema.testersgroup.com/api/UploadvideoDetailbyid/${data.video_id}`)
     .then(res=>{
         if(res.status===200){
             console.log(res.data.data[0])
             setVideodetail(res.data.data[0])
             setLoder(true)
         }
 
     })
     .catch(err=>{
         console.log(err)
     })
    },[])

    return (
        <Fragment>
             {loder===true ?
            <div className='container mt-2'>
                <div className='row'>
                    <div className='col-md-6 text-white col-md-push-6 first'>
                        <nav aria-label=" bg-none">
                            <ol class="d-flex p-0">
                                <li class=""><Link to="/">Home</Link>
                                    <i class="fas fa-angle-double-right"></i>
                                </li>
                                <li class=""><Link to="/talenthuntlist">TalentHunt</Link>
                                    <i class="fas fa-angle-double-right"></i>
                                </li>
                                <li class="" aria-current="page">{videoDetail.title}</li>
                            </ol>
                        </nav>
                        <h4 className=''>
                            {videoDetail.title}
                        </h4>
                       
                        <div className='mt-4 episodedetaildiv  '>
                                <Link className='btn btn-success'
                                 to={{
                                    pathname: '/video',
                                    state: {
                                        item: videoDetail,
                                    }
                                }} 
                               >Play Now</Link>
                                <span className='ml-3'>
                                    <img src={watchlIstimage} width='20px' height='20px' />
                                    <span className='ml-2'>Add TO WatchList</span>
                                </span>
                                <SharePopover />
                                {/* <span className='ml-3'>
                                    <img src={sharelink} width='20px' height='20px' />
                                    <span className='ml-2'>Share</span>
                                </span> */}
                        </div>
                        <div class="read-more mb-12 mt-2" style={{ lineClamp: "3" }}>
                                    <input id="read-more-checkbox" type="checkbox" class="read-more__checkbox" aria-hidden="true" />
                                    <p class="read-more__text mb-2">
                                    {videoDetail.desc}
                                    </p>
                                    <label for="read-more-checkbox" class="read-more__label" style={{ color: '#007bff', boxShadow: 'none' }} data-read-more="Read more" data-read-less="See less" aria-hidden="true"></label>
                        </div>
                    </div>
                    <div className='col-md-6 col-md-pull-6 second'>
                        <div className='' style={{ height: '350px' }}>
                            <img className=''  
                            src={`${LOCAL_SERVER}/${PANCHANG_SERVER}/fetchUplaodImage/${videoDetail.image}`}
                                width='100%' height='100%'
                            />
                        </div>
                    </div>
                </div>
            </div>
            :
            <Loder />
                            }

        </Fragment>
    )
}

export default TalentHuntVideoDetailPage;