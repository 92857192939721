
import React, { Fragment, useEffect, useState } from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import image1 from '../../../assets/movie-banner/1.jpg'
import image2 from '../../../assets/movie-banner/2.jpg'
import image3 from '../../../assets/movie-banner/3.jpg'
import TalentHuntSubCategoryCrousel from '../../../Pages/TalentHuntTab/TalentHuntSubCategoryCrousel';
import { connect } from 'react-redux';
import { GetSubCategoryListByUserId } from '../../../../store/action/TalentHuntVideoList';
import Loder from '../../../resuable/Loder'
import videofile from '../../../../components/assets/video/sample-video.mp4';
import Plyr from 'plyr-react';
import TalenthuntBannerCrousel from './TalentHuntBannerCrousel';
import BannerCrousel from '../../../resuable/bannerCrousel';
import { LOCAL_SERVER,PANCHANG_SERVER } from '../../../../store/config';
import axios from 'axios';

import '../MoviesPage/MoviePage.css';


const TalentHuntHome = (props) => {
    window.scrollTo(0, 0);
    const [videPlayUi, setVideoPlayUi] = useState(false)
    // const lodervalue = props.location.state.loder
    // console.log(lodervalue)
    const [loder, setLoder] = useState(false);
    const [bannerVideo, setBannerVideo] = useState();
    const [SubcategoryList,setSubcategoryList]=useState([])
    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ?
            <button className='btn lefttmoviesbutton' onClick={onClick} disabled={isEdge}>
                <i class="fa fa-chevron-left "></i></button>
            :
            <button className='btn rightmoviesbutton' onClick={onClick} disabled={isEdge}>
                <i class="fa fa-chevron-right"></i></button>

        return (
            pointer
        )
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getTalentHuntsubcategorylist`).then(res=>{ 
            if(res.data.msg==="find success"){
                console.log(res)
                setSubcategoryList(res.data.result)
              }
        
            })
            .catch(err=>{
                console.log(err)
            })
        const catdata={
            category_id:'1'
          }
          axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getBannerList`,catdata).then(res=>{ 
            if(res.data.msg==="list find success"){
                console.log(res)
                setBannerVideo(res.data.result)
                setLoder(true)
              }
        
            })
            .catch(err=>{
                console.log(err)
            })
           
        const data = {
            category_id: 1
        }
        props.GetSubCategoryListByUserIdHandler(data)
    }, [])

    // useEffect(() => {
    //     axios.get('https://apicinema.testersgroup.com/api/getTalenthuntsBannerlist').then(res => {
    //         console.log(res)
    //         setBannerVideo(res.data.result)
    //         setLoder(true)
    //     })
    //     const data = {
    //         category_id: 1
    //     }
    //     setTimeout(() => {
    //     }, 2000)
    // }, [])
    return (
        <Fragment>
            {loder === true ?

                <>
                    <BannerCrousel bannerVideo={bannerVideo} />
                    {props.UploadVideoListBySubCategoryLoder === false ? "Loading" :
                    <TalentHuntSubCategoryCrousel IntroSubcategoryList={SubcategoryList} subcategoryList={props.UploadVideoListBysubcategory} category_id={1} />
                    }
                </>
                :

                <Loder />

            }
        </Fragment >
    )
}
const mapStateToProps = state => ({
    UploadVideoListBysubcategory: state.UploadVideoListByCategoryReducer.UploadVideoListBysubcategory,
    UploadVideoListBySubCategoryLoder: state.UploadVideoListByCategoryReducer.UploadVideoListBySubCategoryLoder,
})

const mapDispatchToProps = dispatch => ({
    GetSubCategoryListByUserIdHandler: data => dispatch(GetSubCategoryListByUserId(data)),

})
export default connect(mapStateToProps, mapDispatchToProps)(TalentHuntHome);
