import React, { Fragment, useEffect, useState } from 'react';
import imagetrendinglabel from '../../components/assets/trending/trending-label.png'
// import { GetSubCategoryListByUserId } from '../../../store/action/TalentHuntVideoList';
import Loder from './Loder';
import MovieSubCategoryListCrousel from '../Pages/MovieTab/MovieSubCategoryListCrousel';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import { GetMovieListSubCategory } from '../../store/action/MovieTabAction';
import WatchListPage from './watchListPage';
import { connect } from 'react-redux';
import xmlfile from '../assets/file.xml';
import add from '../assets/add.xml'
import LikedislikePage from '../resuable/LikedislipkePage/LikedislikePage';
import ReactPlayer from 'react-player'
import PlyrComponent from 'plyr-react';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton, FacebookIcon, WhatsappIcon
} from "react-share";
import CategoryVideoListServices from '../../Services/categoryVideoListServices';
import { useHistory } from 'react-router-dom';
import CategoryVideoListPage from './CategoryVideoList/CategoryVideoList';
import ReadMorecomponent from './ReadMorecomponent';
import CommentPage from '../Pages/CommentPage/CommentPage';
import fluidPlayer from 'fluid-player';
import 'plyr-react/dist/plyr.css';
import "fluid-player/src/css/fluidplayer.css";
import './adminVideoPlayer.scss'


const StudentCornerVideoPlayer = (props) => {
    const history = useHistory()
    // const params=useParams();
    // console.log(params)
    const item = props.location.state.item;
    console.log(item)

    const [loder, setLoder] = useState(false);
    const [self, setSelf] = useState(React.createRef())
    const [CategoryVideoList,setCategoryVideoList]=useState([]);
    const [categoryType,setCategoryType]=useState('')
    // const [player,setPlayer]=useState(null)
    //const player=null
    const contenttype = props.location.state.contenttype;
    const homebreadcampbutton = () => {
        history.push('/HomePage')
    }
    const movieVreadcapm = () => {
        history.push('/Watch-movie')
    }
    const fetchCategoryvideolist=async()=>{
        console.log(item.category_id)
        const category_id=item.category_id===undefined ?"4":item.category_id
        const fetchdata=await CategoryVideoListServices.getCategoryVideoList(category_id)
        if(fetchdata.state===false){
            console.log(fetchdata)
            setCategoryVideoList(fetchdata.response.data.result)
            setCategoryType(fetchdata.response.data.type)
        }
    }

    useEffect(() => {
        fetchCategoryvideolist()
       
        fluidPlayer(
            'my-video',

            {
                layoutControls: {
                    primaryColor: "#28B8ED",
                    loop: true, // Default false
                    doubleclickFullscreen: false, // Default true
                    subtitlesEnabled: true, // Default false
                    posterImage: `https://cinemaadm.testersgroup.com${item.image_path}`, // Default false
                    //  title: 'My video title', // Default false
                    playbackRateEnabled: true, // Default false
                    controlForwardBackward: {
                        show: true // Default: false
                    }
                },
                vastOptions: {
                    showPlayButton: false,
                    maxAllowedVastTagRedirects: 1,

                    vastAdvanced: {
                        vastLoadedCallback: (function () {
                            console.log('play video')
                        }),
                        noVastVideoCallback: (function () { }),
                        vastVideoSkippedCallback: (function () { }),
                        vastVideoEndedCallback: (function () {
                            console.log('play stop')
                        })
                    }
                }
            }
        )
    }, [])
    return (
        <Fragment>
            <div className='container-fluid' >

                <div className='row '>
                    <div className='col-12 px-0' >
                        <div className='admin_kidzjoy_player'>
                            {/* <div className='player-wrapper'>
                            <ReactPlayer
                            style={{marginTop:'0px'}}
                            controls={true}
                            muted={true}
                                // className='react-player'
                                url={``}
                                width='100%'
                                // height='100%'
                            />
                        </div> */}
                            <video id='my-video' ref={self} controls>

                                <source src={`https://cinemaadm.testersgroup.com${item.video_path}`}
                                    data-fluid-hd
                                    title='1080'
                                    type='video/mp4' />
                                <source src={`https://cinemaadm.testersgroup.com${item.video_path}`}
                                    data-fluid-hd
                                    title='1080p'
                                    type='video/mp4' />
                                <source src={`https://cinemaadm.testersgroup.com${item.video_path}`}
                                    data-fluid-hd
                                    title='720p'
                                    type='video/mp4' />
                                <source src={`https://cinemaadm.testersgroup.com${item.video_path}`}
                                    data-fluid-hd
                                    title='480p'
                                    type='video/mp4' />
                                <source src={`https://cinemaadm.testersgroup.com${item.video_path}`}
                                    data-fluid-hd
                                    title='144p'
                                    type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </div>
                <div className='row videoplayerrowdiv mt-5'>
                    <div className='col-12 p-0'>
                        <nav aria-label="bg-none" className='mt-5' style={{ color: '#fff' }}>
                            <ul class="d-flex p-0 space_lines" style={{ listStyle: 'none' }}>
                                <li onClick={homebreadcampbutton} class="" style={{ cursor: 'pointer' }}>Home
                                    <i class="fas fa-angle-double-right"></i>
                                </li>
                                <li class="" onClick={movieVreadcapm} style={{ cursor: 'pointer' }}>Movies
                                    <i class="fas fa-angle-double-right"></i>
                                </li>{item.title ? item.title : ""}
                                <li class="" aria-current="page"></li>
                            </ul>
                        </nav>
                        <div className=" text-uppercase admin_player_title mt-0">
                            <h4 className="trending-text big-title text-uppercase mt-0">{item.title}</h4>
                            <div className="block-social-info">
                                {item.category_id === '2' || item.category_id === '1' ?
                                    <ul className="list-inline p-0 m-0 music-play-lists d-flex justify-content-end">

                                        <LikedislikePage detail={item} />
                                        {/* < WatchListPage item={item} /> */}
                                        {/* <li className='mr-3 '><span className='socialsymbel'><i className="ri-add-line "></i></span></li> */}

                                        {/* <li className='mr-3'><span className='socialsymbel'><i className="ri-heart-fill "></i></span></li> */}
                                        {/* <li><span className='socialsymbel'><i className="ri-share-fill "></i></span></li> */}

                                    </ul>
                                    : ""}
                            </div>
                        </div>
                        <div className='txt_bullet'>

                            <div className="d-flex align-items-center text-white text-detail movietimingtext">
                                <span className=" mr-2"><i className="fas fa-circle" style={{ background: "#00dcd4", color: '#00dcd4', borderRadius: '50%', border: 'none' }}></i>&nbsp;{item.category_name} Movie</span>
                                <span className=" mr-2"><i className="fas fa-circle" style={{ background: "#00dcd4", color: '#00dcd4', borderRadius: '50%', border: 'none' }}></i>&nbsp;{item.subcategory_name} Hindi</span>
                                <span className=" mr-2"><i className="fas fa-circle" style={{ background: "#00dcd4", color: '#00dcd4', borderRadius: '50%', border: 'none' }}></i>&nbsp;2021</span>
                            </div>

                            {/* <div className="d-flex align-items-center series mb-4 mt-4">
                                    <a href="#"><img src={imagetrendinglabel} className="img-fluid" alt="" /></a>
                                    <span className="text-gold ml-3" style={{ color: '#E6C75F', fontWeight: '600' }}>#2 in Series Today</span>
                                </div> */}

                            <p className="trending-dec w-100 mb-0 text-white mt-4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {item.description ?
                                    <ReadMorecomponent text={item.description.replace(/<\/?p[^>]*>/g, "")} />
                                    : ''}


                            </p>
                            {/* <p className='w-100 mb-0 mt-3' style={{ color: '#00dcd4', fontSize: '16px', fontWeight: '500' }}>SEE MORE</p> */}

                        </div>


                    </div>
                </div>
                {item.category_id === '2' ?
                    <div className='row videoplayerrowdiv mt-5'>
                        <CommentPage content_id={item.id} category_id={item.category_id} />
                    </div>
                    : ""}
                <div className='row videoplayerrowdiv mt-5'>
                    <h2 className=" big-title text-uppercase mt-0" style={{ fontSize: '25px' }}>RECOMMENDED</h2>
                </div>
                <div className='row'>
                    <CategoryVideoListPage categoryType={categoryType} VideoList={CategoryVideoList} />
                </div>
                {/* <MovieSubCategoryListCrousel subcategoryList={props.movieListSubCategory} category_id={item.category_id} /> */}
            </div>

        </Fragment >
    )
}

const mapStateToProps = state => ({
    movieListSubCategory: state.MovieTabReducer.getmovieListSubCategory,
    movieListSubCategoryLoder: state.MovieTabReducer.getmovieListSubCategoryLoder,
})

const mapDispatchToProps = dispatch => ({
    GetMovieListSubCategoryHandler: data => dispatch(GetMovieListSubCategory(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(StudentCornerVideoPlayer)