import React, { useEffect, useState, Fragment } from 'react';
import AuthServices from '../../Services/authServices';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { Button, notification, Space,Modal } from 'antd';
import { useHistory } from 'react-router';
import PaymentGetWayServices from '../../Services/Paymentgetwayservice/paymentetwayservices';
import "./demopage.scss"
import ListGroup from 'react-bootstrap/ListGroup';

function Demopage() {
  const history=useHistory()
  const fetchid=localStorage.getItem('id')
  if(!fetchid){
    window.location='/'
  }
  const [userdetail, setUserDetail] = useState([]);
  const [packlist, setPackList] = useState([]);
 // const [commisiondetail, setCommisionDetail] = useState([]);
  const [totalamount, setTotalamount] = useState(0);
  const [buyPackSuccess, setBuyPackSuccess] = useState(false);
  const [UserDiscountDetail,setUserDiscountDetail]=useState([])
  const [promouserid,setpromouserid]=useState('')
  const [subscriptiondate, setsubscriptiondate] = useState(
    { stardate: "", enddate: "" }
  )
 // const [commisionAmount,setCommissionAmount]=useState(0);
  const [Userdiscount,SetUserDiscount]=useState(0)

  const [usertypeList, setUserTypeList] = useState([])

  const [promovideoLink,setPromovideoLink]=useState()
  const [opensignupform, setopensignupform] = useState(false);
  const [showSignup, setshowSignup] = useState(true);
  const [buypackLoader, setBuypackLoader] = useState(true);
  const [loader,setLoader] = useState(false);
  const findCommisionDetail = async (id, type_id, price) => {
    const apicall = await AuthServices.FindCommisionDetail(id, type_id);
    console.log(apicall)
    if (apicall.state === false) {
      console.log(price)
      if (apicall.response.data.success === true) {
        if(apicall.response.data.userpromodetail.length>0){
          setpromouserid(apicall.response.data.userpromodetail[0].promocode_userid)
        }
        // setCommisionDetail(apicall.response.data.commisiondetail)
        // setUserDiscountDetail(apicall.response.data.userdiscount)
         setUserDiscountDetail(0)
        if (apicall.response.data.userdiscount.length > 0) {
          // if(apicall.response.data.userdiscount[0].commision_type===1){
          //   setTotalamount(parseFloat(price) - parseFloat(apicall.response.data.userdiscount[0].amount))
          //   SetUserDiscount(apicall.response.data.userdiscount[0].amount)
          // }
          // else if(apicall.response.data.userdiscount[0].commision_type===2){
          //  const  percentageamount=parseFloat(price) * apicall.response.data.userdiscount[0].amount/100
          //  setTotalamount(parseFloat(price)-percentageamount)
          //  SetUserDiscount(percentageamount)
          // }
            setTotalamount(parseFloat(price))
            SetUserDiscount(0)
        }
        else {
          setTotalamount(parseFloat(price))
        }
      }
    }
  }
  const fetchuserdetail = async () => {
    const userid = localStorage.getItem('id')
    const apicall = await AuthServices.FindUserDetail(userid);
    console.log(apicall)
    if (apicall.state === false) {
      if (apicall.response.data.success === true) {
        findCommisionDetail(userid, apicall.response.data.result[0].type_property_id, apicall.response.data.planlist[0]?.sales_price)
        setPackList(apicall.response.data.planlist)
        setUserDetail(apicall.response.data.result)
        setpromouserid((apicall.response.data.result[0].promoter_code_user_id))
        
      }
    }
  }

  const fetchlist = async () => {
    const callapi = await AuthServices.getusertypelist()
    console.log(callapi)
    if (callapi.state === false) {
      if (callapi.response.data.success === true) {
        setUserTypeList(callapi.response.data.result)
        setPromovideoLink(callapi.response.data.videolonk)

      }
    }
  }
  useEffect(() => {
    fetchlist()
  }, [])

  useEffect(() => {
    if(!fetchid){
      window.location.replace("/");
    }
    else{
      fetchuserdetail()
    }
  }, [])
  const BuypackButton = async (data) => {
    var today = moment().format('l');
    setBuypackLoader(false)
    var next = moment().add(parseInt(packlist[0].days), 'days').calendar();
     next = moment(next).format('DD/MM/YY');
     console.log(next);
    setsubscriptiondate({ stardate: today, enddate: next })
    // const data = {
    //   user_id: localStorage.getItem('id'),
    //   // amount: commisionAmount,
    //   subscrition_id: packlist[0].id,
    //   start_date: today,
    //   promocode_user_id:promouserid,
    //   end_date: next,
    //   total_amount:packlist[0].sales_price,
    //   discountType:UserDiscountDetail.length>0 ? UserDiscountDetail[0].user_type: "" ,
    //   discountValue:Userdiscount,
    //   netAmount:totalamount,
    // }
    // console.log(data)
    const apicall = await AuthServices.BuyPack(data)
    console.log(apicall)
    if(apicall.state===false){
      setBuypackLoader(true)
      if(apicall.response.data.success===true){
        console.log('success msg')
        setBuyPackSuccess(true)
        notification['success']({
          message: 'Payment is successful',
          description:'Your Plan Is Active.'
        });
      }
    }
    else{
      setBuypackLoader(true)
      console.log('something error')
      notification['error']({
        message: 'Something Error!',
      });
    }
    
  }
  const buybuttonclick=async()=>{
    var today = moment().format('l');
    setLoader(true);
    var next = moment().add(parseInt(packlist[0].days), 'days').calendar();
    const data = {
      user_id: localStorage.getItem('id'),
      // amount: commisionAmount,
      subscrition_id: packlist[0].id,
      start_date: today,
      promocode_user_id:promouserid,
      end_date: next,
      total_amount:packlist[0].sales_price,
      discountType:UserDiscountDetail.length>0 ? UserDiscountDetail[0].user_type: "" ,
      discountValue:Userdiscount,
      netAmount:totalamount,
    }
    console.log(data)
    const apicall=await PaymentGetWayServices.PayService(userdetail,BuypackButton,totalamount,data,setBuypackLoader)
    console.log(apicall)
    setLoader(false);
    setsubscriptiondate({ stardate: today, enddate: next })
  }
  const LoginHomepageButton=async()=>{
    const data={
      email:userdetail[0].email
    }
      const apicall=await AuthServices.loginwithoutpassword(data)
      console.log(apicall)
      if(apicall.state===false){
        if(apicall.response.data.success===true){
          notification['success']({
            message: 'Payemnt Success!',
            description:'Your Plan Is Active.'
          })
          localStorage.removeItem('id')
          localStorage.setItem("loginid", apicall.response.data.result[0].id);
          localStorage.setItem("user_token", apicall.response.data.token);
          window.location='/HomePage'
        }
      }
      else{
        console.log('something error')
        notification['error']({
          message: 'Something Error!',
        });
        window.location='/'
      }

  }
  const signoutbuypage=()=>{
    localStorage.removeItem('id')
    history.push('/')
  }

  const changepackage = ()=>{
    setopensignupform(false)
  }
   const loginuserbutton = async (type, id) => {
      setopensignupform(true)
       
    const apicall = await AuthServices.ChoosePackage(localStorage.getItem('id'),id);
    console.log(apicall)
    if (apicall.state === false) {
      if (apicall.response.data.success === true) {
        setPackList(apicall.response.data.planlist)
        setTotalamount(apicall.response.data.planlist[0].sales_price)
        console.log(apicall.response.data.planlist[0].pack_type)
      }
    }
    }
    const signupbuttonClick = (modal2Visible) => {
      setshowSignup(modal2Visible)
    };
  return (
    <Fragment>
      {userdetail.length > 0 ?
        <>
          {buyPackSuccess === false ?
            <>
              // Chooise Package
              {
                opensignupform === false?(<>
                
                <div className='container'>
                <div className='row'>
                          <div className='msg_main text-right' style={{width:'100%'}}>
                          <button className='btn btn-danger sign-out text-bold py-3 px-5' onClick={signoutbuypage}><i class="fa-solid fa-right-from-bracket"></i> Sign Out</button>
                            </div>
                          </div>
                <div className='row'>
                 <div className='col-md-12'>
                    <div className='text-center'>
                        <img src='static/media/logo.1de6dc03.png' width="220px"/>
                    </div>
                    <br/>
                    <h2 className='heading-main text-white mb-5'>Choose the plan that’s right for you</h2>
                 </div>
                 </div>
                 <div className='plan-main'>
                 <div className='row g-5'>
                {usertypeList.map((item, index) => {

                return (
                  <>
                    <div className='col-md-6' style={{cursor:'pointer'}}>
                      <div className='plan-box'>
                      <ListGroup.Item className='price-card' key={index} variant="primary" 
                      onClick={() => loginuserbutton(item.type_name, item.property_id)}>
                        {item.type_name} <br/> <h4 className='plan-rate'>₹{item.packageprice}</h4></ListGroup.Item>
                      {item.property_id === "2" ?
                      ""
                        : ""} 
                      </div>
                    </div>
                   
                   
                  </>
                )
                })}
                  
                </div>
                 </div>
              </div>
                </>):""
              }
             
              {/* <Modal className='signupmodel'
                destroyOnClose={true}
                centered
                visible={showSignup}
                onOk={() => signupbuttonClick(false)}
                onCancel={() => signupbuttonClick(false)}
              >{opensignupform === false ?
                <>
                
                  <h2 className='text-white text-center create_account_header_text'>Create Account with</h2>
                  <ListGroup className='userloginlistgroup'>
                    {usertypeList.map((item, index) => {

                      return (
                        <>
                          <ListGroup.Item key={index} variant="primary" 
                          onClick={() => loginuserbutton(item.type_name, item.property_id)}>
                            {item.type_name} ( FEES RS.{item.packageprice} )</ListGroup.Item>
                          {item.property_id === "2" ?
                          ""
                            : ""}
                        </>
                      )
                    })}
                    </ListGroup>
                </>
                :
                  
                  ""   
                  
                }
              </Modal> */}
              {
                opensignupform && (<>
                  <div className='container'>
                        <div className='row'>
                          <div className='msg_main text-right' style={{width:'100%'}}>
                            <button className='sign-out btn btn-danger text-bold py-3 px-5' onClick={signoutbuypage}><i class="fa-solid fa-right-from-bracket"></i> Sign Out</button>
                            </div>
                          </div>
                        
                      </div>
                      {
                        buypackLoader?(<>
                        <div className='container'>
                        
                        <div className='row'>
                          <div className='msg_main'>
                            <div className='card_msg' style={{background:'radial-gradient(140.76% 131.96% at 100% 100%, rgb(33, 114, 227) 0%, rgba(74, 42, 150, 0.5) 73.57%, rgba(74, 42, 150, 0) 100%), rgb(29, 82, 157)'}}>
                              <p>Your Account has been Created, Kindly proceed with payment</p>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div className='container'>
                        <div className='row'>
                          <div className='msg_main'>
                            <div className='card_msg'>
                              <div className='pay_info pt-5'>
                                <div className='one_side'>
                                  <p>Selected Package : <span>{packlist[0]?.plan_name}</span></p>
                                  <span className='change-package-btn' style={{cursor:'pointer'}} onClick={changepackage}><i class="fa-solid fa-arrow-left"></i> Change Package</span>
                                </div>
                                <div className='two_side'>
                                  <p>₹{packlist[0]?.sales_price}</p>
                                </div>
                              </div>
                              {UserDiscountDetail.length > 0 ?
                                <div className='pay_info'>
                                  <div className='one_side'>
                                    <p>{userdetail[0].type} Code Discount</p>
                                  </div>
                                  <div className='two_side'>
                                    <p>{Userdiscount}</p>
                                  </div>
                                </div>
                                : ""}
                                <hr style={{color:'#fff'}}/>
                              <div className='pay_info'>
                                <div className='one_side'>
                                  <p>Amount to Pay</p>
                                </div>
                                <div className='two_side'>
                                  <p>₹{totalamount}</p>
                                </div>
                              </div>
                              <div className='pay_info pb-5'>
                                <div className='one_side'>

                                </div>
                                <div className='two_side mt-4'>
                                  <div className='pay_now_kdz'>
                                    <a className='ant-btn ant-btn-primary'
                                     onClick={buybuttonclick}
                                     disabled = {`${loader?"disabled":""}`}
                                     >
                                      {
                                                            loader ? (
                                                                <>
                                                                <span type="primary"  htmlType="submit" className="spinner-border spinner-border-sm" role="status"
                                                                aria-hidden="true"></span>{" "}Loading...
                                                            </>
                                                            ):(<><i class="fa-solid fa-money-bill-wave"></i> Pay now</>)
                                                        }
                                    </a>
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        </>):(<>
                          <div className='container'>
                          <div className='row'>
                            <div className='msg_main'>
                              <div className='card_msg'>
                                <p>Please Wait...</p>
                              </div>
                            </div>
                          </div>
                          </div>
                        </>)
                      }
                      
                </>)
              }
             
            </>
            :
            <>
              {/* <div className='container'>
                <div className='row'>
                  <div className='msg_main'>
                    <div className='card_msg'>
                      <div className='sccess_pay'>
                        <p>You have paid successfully and Your Account is now active.</p>
                        <p>Account Renewel Date Will Be : 21 FEB 2023</p>
                        <div className='btn_contine text-center mt-5'>
                          <a className='ant-btn ant-btn-primary'>Continue to Use Application</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className='container'>
                <div className='row'>
                  <div className='msg_main'>
                    <div className='card_msg text-center'>
                      <div className='sccess_pay pb-0'>
                        <p className='ps-2 text-center'>You have paid successfully and Your Account is now active.</p>
                      </div>
                      <div className='pay_info p-0 d-block'>
                        <div className='one_side'>
                          <div className='sccess_pay pt-0'>
                            <p className='text-center'>Account Renewel Date Will Be : {subscriptiondate.enddate}</p>
                            {/* {userdetail[0].promoter_code === null ? "" :
                              <p>Your {userdetail[0].type} Code is : {userdetail[0].promoter_code} &nbsp;&nbsp;&nbsp; <span><a className='pay_now_kdz ant-btn ant-btn-primary'>Share Now</a></span></p>
                            } */}
                          </div>
                        </div>
                      </div>
                      <div className='sccess_pay'>
                        <div className='btn_contine text-center'>
                          <a onClick={LoginHomepageButton} className='ant-btn'>Continue to Use Application</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </>
        : ""}
    </Fragment>

  )
}

export default Demopage