import React, { Fragment, useEffect, useState } from 'react';
import imagetrendinglabel from '../../components/assets/trending/trending-label.png'
// import { GetSubCategoryListByUserId } from '../../../store/action/TalentHuntVideoList';
import MovieSubCategoryListCrousel from '../Pages/MovieTab/MovieSubCategoryListCrousel';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import { GetMovieListSubCategory } from '../../store/action/MovieTabAction';
import WatchListPage from './watchListPage';
import { connect } from 'react-redux';
import xmlfile from '../assets/file.xml';
import LikedislikePage from '../resuable/LikedislipkePage/LikedislikePage';
import ReactPlayer from 'react-player'
import PlyrComponent from 'plyr-react';
import parse from "html-react-parser";
import OpenPlayerJS from 'openplayerjs';
import AdvertiseServices from '../../Services/AdvertisementService/Adservices';
import videofile from '../assets/file.xml';
import add from '../assets/add.xml';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton, FacebookIcon, WhatsappIcon
} from "react-share";
import addfile from '../assets/add.xml';
import CategoryVideoListServices from '../../Services/categoryVideoListServices';
import { useHistory } from 'react-router-dom';
import CategoryVideoListPage from './CategoryVideoList/CategoryVideoList';
import ReadMorecomponent from './ReadMorecomponent';
import CommentPage from '../Pages/CommentPage/CommentPage';
// import fluidPlayer from 'fluid-player';
import axios from 'axios';
import Fluidplayers from './fluidplayer';
import "fluid-player/src/css/fluidplayer.css";
import './adminVideoPlayer.scss'




const AdminVideoPlayer = (props) => {
    const history = useHistory()
    // const params=useParams();
    // console.log(params)
    const item = props.location.state.item;
    const categoryname=props.location.state.categoryname
    const language=props.location.state.language
    const contenttype=props.location.state.contenttype
    console.log(item)
    const [playerObj, setPlayer] = useState();
    const [loder, setLoder] = useState(false);
    const [self, setSelf] = useState(React.createRef())
    const [CategoryVideoList, setCategoryVideoList] = useState([]);
    const [categoryType, setCategoryType] = useState('');
    const [at_began, setAtbegan] = useState({ type: "", path: "" });
    const [at_pause, setAtpause] = useState({ type: "", path: "" });
    const [at_time, setattime] = useState({ type: "", path: "" });
    const [xmlfiles, setxmlfile] = useState();
    const [startPLayer, setStartPlayer] = useState(false);
    const [add, setadd] = useState(false);
    const [adlistdata,setAddlistdata]=useState([])


    // const [player,setPlayer]=useState(null)
    //const player=null
    //const contenttype = props.location.state.contenttype;
    const homebreadcampbutton = () => {
        history.push('/HomePage')
    }
    const movieVreadcapm = () => {
        if(item.category_id==="3"){
            history.push('/Watch-movie')
           }
           else if(item.category_id==="5"){
            history.push('/shortfilmpage')
           }
    }
    const fetchCategoryvideolist = async () => {
        console.log(item.category_id)
        const category_id = item.category_id === undefined ? "4" : item.category_id
        const fetchdata = await CategoryVideoListServices.getCategoryVideoList(category_id)
        if (fetchdata.state === false) {
            //console.log(fetchdata)
            setCategoryVideoList(fetchdata.response.data.result)
            setCategoryType(fetchdata.response.data.type)
        }
    }
    // fetchCategoryvideolist()
    const trigger=async()=>{
        const apidata = {
            video_id:item.id,
            category_id:item.category_id,
            sub_category_id:item.subcategory_id
            // video_id: "135",
            // category_id: "1",
            // sub_category_id: "3"
        }
        const fetchdata = await AdvertiseServices.getVideoadlist(apidata)
        console.log(fetchdata)
        if (fetchdata.state === false) {
            fetchCategoryvideolist()
            //  console.log(fetchdata)
            if (fetchdata.response.data.success === true) {
                console.log(fetchdata)
                const relt = fetchdata.response.data.result
                const adarray=[]
            //   const newarray=  adarray.concat(relt.at_begin)
            //   const newarray1=  adarray.concat(relt.at_pause)
            //   const newarray2=  adarray.concat(relt.at_particlar)
            //     console.log(newarray2)
            const firstarr=relt.at_begin
            setAddlistdata({
                ...adlistdata,firstarr
              })
               // setAddlistdata([relt.at_begin,relt.at_pause,relt.at_particlar])
                // if (relt.at_begin.type !== undefined) {
                    // console.log(fetchdata)
                    // if (relt.at_begin.type !== "") {
                    setAtbegan({id:relt.at_begin.type ? relt.at_begin.id:'', type: relt.at_begin.type, path: relt.at_begin.file_path })
                    setAtpause({id:relt.at_pause.type ? relt.at_pause.id:'', type: relt.at_pause.type ? relt.at_pause.type : "", path: relt.at_pause.file_path ? relt.at_pause.file_path : "" })
                    setattime({id:relt.at_particlar.type ? relt.at_particlar.id:'', type: relt.at_particlar.type ? relt.at_particlar.type : "", path: relt.at_particlar.file_path ? relt.at_particlar.file_path : "" })
                    console.log(relt)
                    console.log(relt.at_begin.file_path)
                    var ad = []
               relt.at_begin.map((item, index) => {
                    if(item){
                        ad.push(
                            {   
                                adid:item.id,
                                filepath:`https://cinemaadm.testersgroup.com${item.file_path}`,
                                roll: 'preRoll',
                               // vastTag:`${xmlfile}`,
                             vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${item.id}`,
                                // adText: 'Advertising supports us directly'
                            }
                        )
                    }
                    else{
                        // ad.push(
                        //     {
                        //         roll: 'preRoll',
                        //        // vastTag:`${xmlfile}`,
                        //      vastTag: '',
                        //         // adText: 'Advertising supports us directly'
                        //     }
                        // )
                    }
                    
                })
                   
                        relt.at_particlar.slice(0).reverse().map((item, index) => {
                            if(item){
                                ad.push(
                                    {
                                        adid:item.id,
                                        filepath:`https://cinemaadm.testersgroup.com${item.file_path}`,
                                        roll: 'midRoll',
                                       //  vastTag:`${xmlfile}`,
                                      vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${item.id}`,
                                        timer:item.at_particular_time
                                    }
                                )
                            }
                           
                        })
                    
                   
                        relt.at_pause.map((item, index) => {
                            if(item){
                                ad.push(
        
                                    {
                                        adid:item.id,
                                        filepath:`https://cinemaadm.testersgroup.com${item.file_path}`,
                                        "roll": "onPauseRoll",
                                      //  vastTag:`${addfile}`,
                                       vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${item.id}`,
                                        "adText": "",
                                    }
                                )
                            }

                        })
                    
                    console.log(ad)
                    ad.push({
                        roll: "preRoll",
                vastTag: "https://apicinema.testersgroup.com/api/getxmlfile/thsyu"
                    })
                    setadd(ad)
                    setStartPlayer(true)
            }
        }
       }
       useEffect(()=>{
        trigger()
        // fetchCategoryvideolist()
       },[])
    return (
        <Fragment>
            {startPLayer === true ?
            <div className='container-fluid' >

                <div className='row '>
                    <div className='col-12 px-0' >
                        <div className='admin_kidzjoy_player'>
                            <Fluidplayers detail={item}  name='admin' image={item.image_path} add={add}  item={item.video_path} />
                        </div>
                    </div>
                </div>
                <div className='row videoplayerrowdiv mt-5'>
                    <div className='col-12 p-0'>
                        <nav aria-label="bg-none" className='mt-5' style={{ color: '#fff' }}>
                            <ul class="d-flex p-0 space_lines" style={{ listStyle: 'none' }}>
                                <li onClick={homebreadcampbutton} class="" style={{ cursor: 'pointer' }}>Home
                                    <i class="fas fa-angle-double-right"></i>
                                </li>
                                <li class="" onClick={movieVreadcapm} style={{ cursor: 'pointer' }}>{categoryname}
                                    <i class="fas fa-angle-double-right"></i>
                                </li>{item.title ? item.title : ""}
                                <li class="" aria-current="page"></li>
                            </ul>
                        </nav>
                        <div className=" text-uppercase admin_player_title mt-0">
                            <h4 className="trending-text big-title text-uppercase mt-0">{item.title}</h4>
                            <br></br>
                            <div className="block-social-info">
                                {item.category_id === '2' || item.category_id === '1' ?
                                    <ul className="list-inline p-0 m-0 music-play-lists d-flex justify-content-end">

                                        <LikedislikePage detail={item} />
                                        {/* < WatchListPage item={item} /> */}
                                        {/* <li className='mr-3 '><span className='socialsymbel'><i className="ri-add-line "></i></span></li> */}

                                        {/* <li className='mr-3'><span className='socialsymbel'><i className="ri-heart-fill "></i></span></li> */}
                                        {/* <li><span className='socialsymbel'><i className="ri-share-fill "></i></span></li> */}

                                    </ul>
                                    : ""}
                            </div>
                        </div>
                        <div className='txt_bullet'>

                            <div className="d-flex align-items-center text-white text-detail movietimingtext">
                                {contenttype ? 
                                <span className=" mr-2"><i className="fas fa-circle" style={{ background: "#00dcd4", color: '#00dcd4', borderRadius: '50%', border: 'none' }}></i>&nbsp;{contenttype}</span>
                                :""}
                                {item.language ? 
                                <span className=" mr-2"><i className="fas fa-circle" style={{ background: "#00dcd4", color: '#00dcd4', borderRadius: '50%', border: 'none' }}></i>&nbsp;{item.language}</span>
                                :""}
                                {item.year ? 
                                <span className=" mr-2"><i className="fas fa-circle" style={{ background: "#00dcd4", color: '#00dcd4', borderRadius: '50%', border: 'none' }}></i>&nbsp;{item.year}</span>
                                :""}
                            </div>

                            {/* <div className="d-flex align-items-center series mb-4 mt-4">
                                    <a href="#"><img src={imagetrendinglabel} className="img-fluid" alt="" /></a>
                                    <span className="text-gold ml-3" style={{ color: '#E6C75F', fontWeight: '600' }}>#2 in Series Today</span>
                                </div> */}

                            <p className="trending-dec w-100 mb-0 text-white mt-4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {item.description ?
                                    <ReadMorecomponent text={item.description.replace(/<\/?p[^>]*>/g, "")} />
                                    : ''}


                            </p>
                            {/* <p className='w-100 mb-0 mt-3' style={{ color: '#00dcd4', fontSize: '16px', fontWeight: '500' }}>SEE MORE</p> */}

                        </div>


                    </div>
                </div>
                {item.category_id === '2' ?
                    <div className='row videoplayerrowdiv mt-5'>
                        <CommentPage content_id={item.id} category_id={item.category_id} />
                    </div>
                    : ""}
                <div className='row videoplayerrowdiv mt-5'>
                    <h2 className=" big-title text-uppercase mt-0" style={{ fontSize: '25px' }}>RECOMMENDED</h2>
                </div>
                <div className='row'>
                    <CategoryVideoListPage categoryType={categoryType} VideoList={CategoryVideoList} />
                </div>
                {/* <MovieSubCategoryListCrousel subcategoryList={props.movieListSubCategory} category_id={item.category_id} /> */}
            </div>
            :""}

        </Fragment >
    )
}

const mapStateToProps = state => ({
    movieListSubCategory: state.MovieTabReducer.getmovieListSubCategory,
    movieListSubCategoryLoder: state.MovieTabReducer.getmovieListSubCategoryLoder,
})

const mapDispatchToProps = dispatch => ({
    GetMovieListSubCategoryHandler: data => dispatch(GetMovieListSubCategory(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(AdminVideoPlayer)

// useEffect(() => {
//     console.log(at_began)
//     const player = new OpenPlayerJS("my-player", {
//         ads: {
//             customClick: {
//                 enabled: true,
//                 label: 'Click here',
//             },
//             vpaidMode: 'enabled',
//             src: at_began.type === 'video' ?
//                 `<VAST xmlns:xsi="https://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="vast.xsd" version="3.0">
//             <Ad id="1620399">
//             <InLine>
//             <AdSystem>ExoClick</AdSystem>
//             <AdTitle/>
//             <Impression id="exotr">
//             <![CDATA[ https://syndication.exoclick.com/vregister.php?a=vimp&tracking_event=impression&idzone=2366423&e7511adae629fe65edaa8763c13f213d=tsVuZ8uHLjt48tvLnq4dfXDv66dddlTlK8E.fLhy3ce3jd467uvDW1NZLXThmKUBrgbjYlesYecz6cddUFbi781VcrEjmbl8zUcDUW2CWqulxiTc1NJrgbYbtcprgqcpz6devjn31wNz2MxwVPuU59OvXxz764G6oK3M.3Hlw599cDeM0rmfLn27dOXPXA20xW49NThn14eNcDbTEk7ED0ufHty4c_PnXA3axTAxXBNLn148OvTpw5a4G5qs.nDXA2zTNdU5Tny1wNtuWwNOZ8NcDbTFNMDlOfDXA3BVPnx69OWuqxnPhrtYjscz4a57GY4Kn3KV6WK3M._DXPYzHBU.5Su1ZTS5K1hmCidraYknYgelXasppclawzBRO1uXtPsSvOL1zLz2MxwVPuU58dbl7T7Erzi9cy8rld01MWfHdx5eOWthtevCdzPj41uzUyMV564G5XK7pqYs.OtqayWunBeamB6CViPMUoDW_XXOveu7NTcxS242u7NTnrgbnpmbsarXaYrcempwz4.Nc9MDUEry8kzbkefXW_XXPVnx11NUuOSr0uVTR2VwTS567KnKV4G8.3Frpzc5c2e7PDn43eeXLv27d.XPpz78PHPvy8a7KY132Kn82.nFvw0358O8ODPLh0c8.OHDz58ONO.OnLu5rgknpcqqgmlXqrYrsqz464JJ6XKqoJpV4JbWI4G16XGKppc.Gulx1ylyleqCtxd.aquViRzPWw2zHM1Fnw1wNzOuuU58NcDcbErcEry87DzmfDW5e41ZXBNKvXBI5nw3cOOuBttithpyWtynPlrgbaYppgcpXqmspacz4a5ZqmqYJ68.GuCVqZ6WCuZeSZtzPhrrcqrXkmbcz4a6XHoJpV3nJpWJHF4G8.PLry8eOOuema_BeqtiuyrPbx1wNzsU1yuU58NbUFeC7zk0rEji8DefHl38c.PTXK5Ww1ZBXgvPTNfgvXhO5nrlcrYasgrwXnpmvwXbcqapgnrgmlz1sNsxzNRL2uU564JJ6XKqoJpV2I414JbWI4G16XGKppas.Guqxnlnw11WM88.GupqmCetevCdzPXU1TBPWvKxI5nrqapgnrXtcpz1s0zXVOUr2uU5.e2u2nPhrglrcplYjz4a5Zl3bJW6s.GuBulyqeaWqC1xeNjCayvPhrgbksjrgxmlcz4a7KnKV2mJ54JXs.GuypyldpieeCV5d2lyixyVrDPhrckYgjXgqnz4a6mqYJ6123K2II8.2upqmCete1ymqCaXPjrtssgbz49unDl16cO3Xjx6ee_Xvw7du3Frp0Y78eXlnyxrrgkcqrYknz49unDl16cO3XW1NNFA41NLU5LXnxg- ]]>
//             </Impression>
//             <Error>
//             <![CDATA[ https://syndication.exoclick.com/vregister.php?a=vview&errorcode=[ERRORCODE]&idzone=2366423&dg=1620399-51054402-2-0-0-InLine ]]>
//             </Error>
//             <Creatives>
//             <Creative sequence="1" id="51054402">
//             <Linear skipoffset="00:00:02">
//             <Duration>00:00:20.54</Duration>
//             <TrackingEvents>
//             <Tracking id="prog_1" event="progress" offset="00:00:10.000">
//             <![CDATA[ https://kidzjoy.org/ ]]>
//             </Tracking>
//             </TrackingEvents>
//             <VideoClicks>
//             <ClickThrough>
//             <![CDATA[ https://kidzjoy.org/]]>
//             </ClickThrough>
//             </VideoClicks>
//             <MediaFiles>
//             <MediaFile delivery="progressive" type="video/mp4">
//             <![CDATA[ ${at_began.path}]]>
//             </MediaFile>
//             </MediaFiles>
//             <Icons>
//             <Icon>
//             <IconClicks>
//             <IconClickThrough>https://kidzjoy.org/</IconClickThrough>
//             </IconClicks>
//             </Icon>
//             </Icons>
//             </Linear>
//             </Creative>
//             </Creatives>
//             </InLine>
//             </Ad>
//         </VAST>`: ""
//         }
//         ,
//         src:
//             `<VAST xmlns:xsi="https://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="vast.xsd" version="3.0">
//         <Ad id="1620399">
//         <InLine>
//         <AdSystem>ExoClick</AdSystem>
//         <AdTitle/>
//         <Impression id="exotr">
//         <![CDATA[ https://syndication.exoclick.com/vregister.php?a=vimp&tracking_event=impression&idzone=2366423&e7511adae629fe65edaa8763c13f213d=tsVuZ8uHLjt48tvLnq4dfXDv66dddlTlK8E.fLhy3ce3jd467uvDW1NZLXThmKUBrgbjYlesYecz6cddUFbi781VcrEjmbl8zUcDUW2CWqulxiTc1NJrgbYbtcprgqcpz6devjn31wNz2MxwVPuU59OvXxz764G6oK3M.3Hlw599cDeM0rmfLn27dOXPXA20xW49NThn14eNcDbTEk7ED0ufHty4c_PnXA3axTAxXBNLn148OvTpw5a4G5qs.nDXA2zTNdU5Tny1wNtuWwNOZ8NcDbTFNMDlOfDXA3BVPnx69OWuqxnPhrtYjscz4a57GY4Kn3KV6WK3M._DXPYzHBU.5Su1ZTS5K1hmCidraYknYgelXasppclawzBRO1uXtPsSvOL1zLz2MxwVPuU58dbl7T7Erzi9cy8rld01MWfHdx5eOWthtevCdzPj41uzUyMV564G5XK7pqYs.OtqayWunBeamB6CViPMUoDW_XXOveu7NTcxS242u7NTnrgbnpmbsarXaYrcempwz4.Nc9MDUEry8kzbkefXW_XXPVnx11NUuOSr0uVTR2VwTS567KnKV4G8.3Frpzc5c2e7PDn43eeXLv27d.XPpz78PHPvy8a7KY132Kn82.nFvw0358O8ODPLh0c8.OHDz58ONO.OnLu5rgknpcqqgmlXqrYrsqz464JJ6XKqoJpV4JbWI4G16XGKppc.Gulx1ylyleqCtxd.aquViRzPWw2zHM1Fnw1wNzOuuU58NcDcbErcEry87DzmfDW5e41ZXBNKvXBI5nw3cOOuBttithpyWtynPlrgbaYppgcpXqmspacz4a5ZqmqYJ68.GuCVqZ6WCuZeSZtzPhrrcqrXkmbcz4a6XHoJpV3nJpWJHF4G8.PLry8eOOuema_BeqtiuyrPbx1wNzsU1yuU58NbUFeC7zk0rEji8DefHl38c.PTXK5Ww1ZBXgvPTNfgvXhO5nrlcrYasgrwXnpmvwXbcqapgnrgmlz1sNsxzNRL2uU564JJ6XKqoJpV2I414JbWI4G16XGKppas.Guqxnlnw11WM88.GupqmCetevCdzPXU1TBPWvKxI5nrqapgnrXtcpz1s0zXVOUr2uU5.e2u2nPhrglrcplYjz4a5Zl3bJW6s.GuBulyqeaWqC1xeNjCayvPhrgbksjrgxmlcz4a7KnKV2mJ54JXs.GuypyldpieeCV5d2lyixyVrDPhrckYgjXgqnz4a6mqYJ6123K2II8.2upqmCete1ymqCaXPjrtssgbz49unDl16cO3Xjx6ee_Xvw7du3Frp0Y78eXlnyxrrgkcqrYknz49unDl16cO3XW1NNFA41NLU5LXnxg- ]]>
//         </Impression>
//         <Error>
//         <![CDATA[ https://syndication.exoclick.com/vregister.php?a=vview&errorcode=[ERRORCODE]&idzone=2366423&dg=1620399-51054402-2-0-0-InLine ]]>
//         </Error>
//         <Creatives>
//         <Creative sequence="1" id="51054402">
//         <Linear skipoffset="00:00:02">
//         <Duration>00:00:20.54</Duration>
//         <TrackingEvents>
//         <Tracking id="prog_1" event="progress" offset="00:00:10.000">
//         <![CDATA[ https://kidzjoy.org/ ]]>
//         </Tracking>
//         </TrackingEvents>
//         <VideoClicks>
//         <ClickThrough>
//         <![CDATA[ https://kidzjoy.org/]]>
//         </ClickThrough>
//         </VideoClicks>
//         <MediaFiles>
//         <MediaFile delivery="progressive" type="video/mp4">
//         <![CDATA[ ${vidurl}]]>
//         </MediaFile>
//         </MediaFiles>
//         <Icons>
//         <Icon>
//         <IconClicks>
//         <IconClickThrough>https://kidzjoy.org/</IconClickThrough>
//         </IconClicks>
//         </Icon>
//         </Icons>
//         </Linear>
//         </Creative>
//         </Creatives>
//         </InLine>
//         </Ad>
//     </VAST>`

//     });



  
// }, [])