import React, { useEffect, useRef } from 'react';
import fluidPlayer from 'fluid-player';
import xmlfile from '../assets/file.xml';
import add from '../assets/add.xml';
import AdvertiseServices from '../../Services/AdvertisementService/Adservices';


function Fluidplayers(props) {
    const videoref = useRef()
    const { detail, item, add, image, name } = props;
    console.log(add)
    //add
    const postadcounter = async (data) => {
        const callapi = await AdvertiseServices.addVideoadCounter(data)

    }
    useEffect(() => {
        const player = fluidPlayer(
            "video-vpaid-ads",
            {
                layoutControls: {
                    doubleclickFullscreen: false, // Default true
                    subtitlesEnabled: true, // Default false
                    posterImage: `${image}`, // Default false
                    title: '', // Default false
                    playbackRateEnabled: true, // Default false
                    controlForwardBackward: {
                        show: true // Default: false
                    }
                },

                vastOptions: {
                    adList: add.reverse(),
                    skipButtonCaption: 'Skip ad in [seconds]',
                    skipButtonClickCaption: 'Skip ad <span class="skip_button_icon"></span>',
                    adText: null,
                    adTextPosition: 'top left',
                    adCTAText: 'Visit now!',
                    adCTATextPosition: 'bottom right',
                    vastTimeout: 5000,
                    showPlayButton: false,
                    maxAllowedVastTagRedirects: 10,

                    vastAdvanced: {
                        vastLoadedCallback: (function () {
                            console.log('ad play')
                        }),
                        noVastVideoCallback: (function () {
                            console.log('1')
                        }),
                        vastVideoSkippedCallback: (function () {
                            console.log('2')
                            console.log(videoref.current.currentSrc)
                            const videolink = `${videoref.current.currentSrc}`

                            const adddata = add.filter((el) => {
                                return el.filepath === videolink
                            })
                            if (adddata.length > 0) {
                                const apidata = {
                                    "ad_id": adddata[0].adid,
                                    "video_id": detail.id,
                                    "user_id": localStorage.getItem('loginid'),
                                    "category_id": detail.category_id,
                                    "subcategory_id": detail.subcategory_id
                                }
                                postadcounter(apidata)
                            }
                        }),
                        vastVideoEndedCallback: (function () {
                            console.log('3')
                            const videolink = `${videoref.current.currentSrc}`
                            const adddata = add.filter((el) => {
                                return el.filepath === videolink
                            })
                            if (adddata.length > 0) {
                                const apidata = {
                                    "ad_id": adddata[0].adid,
                                    "video_id": detail.id,
                                    "user_id": localStorage.getItem('loginid'),
                                    "category_id": detail.category_id,
                                    "subcategory_id": detail.subcategory_id
                                }
                                postadcounter(apidata)
                            }
                        })
                    }
                }


            }
        )
        // player.on('timeupdate', function (time) {  })
        player.on('pause', function () {
            console.log('player on pause')
            if (add.length > 0) {
                const data = document.getElementById('video-vpaid-ads')
                const videolink = `${data.src}`
                const adddata = add.filter((el) => {
                    return el.filepath === videolink.replace(/%20/g, " ")
                })
                if (adddata.length > 0) {
                    const apidata = {

                        "ad_id": adddata[0].adid,
                        "video_id": detail.id,
                        "user_id": localStorage.getItem('loginid'),
                        "category_id": detail.category_id,
                        "subcategory_id": detail.subcategory_id
                    }
                    const videourl = `${videoref.current.currentSrc}`
                    if (name === "user") {
                        if (videourl.replace(/%20/g, " ") === `${item}`) {
                            postadcounter(apidata)
                        }
                    }
                    else {
                        if (videourl.replace(/%20/g, " ") === `https://cinemaadm.testersgroup.com${item}`) {
                            postadcounter(apidata)
                        }
                    }
                }
            }

        });
    }, [])
    return (
        <>
            <video ref={videoref}
                id='video-vpaid-ads'
            >
                {name === "user" ?
                    <source src={item}
                        // data-fluid-hd
                        // title='1080'
                        type='video/mp4' />
                    :
                    <source src={`https://cinemaadm.testersgroup.com${item}`}
                        // data-fluid-hd
                        // title='1080'
                        type='video/mp4' />
                }
            </video>
        </>
    )
}

export default Fluidplayers